import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  SafeHtmlPipe }   from './shared/pipes/safe-html.pipe';
import {  FolderFilterPipe }   from './shared/pipes/folder-filter.pipe';
import {  MyFilterPipe }   from './shared/pipes/my-filter.pipe';
import {  FilterUniqueNamePipe }   from './shared/pipes/unique-name.pipe';
import {TruncatePipe} from './app.pipe';

import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { ReversePipe } from './reverse.pipe';
import {SafeResourcePipe} from './safe-resource.pipe';
// import {  FilterUniqueQuestionPipe }   from './shared/pipes/unique-question.pipe';
import { OrderByPipe } from './shared/pipes/order-by.pipe';
import { ConvertFrom24To12FormatPipe } from './convert-from24-to12-format.pipe';
import { MailContentComponent } from './mail-content/mail-content.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { FormsModule } from '@angular/forms';


@NgModule({
	declarations: [
		SafeHtmlPipe,
		FolderFilterPipe,
		InfiniteScrollComponent,
		TruncatePipe,
		MyFilterPipe,
		ReversePipe,
		FilterUniqueNamePipe,
    SafeResourcePipe,
	OrderByPipe,
	ConvertFrom24To12FormatPipe,
	MailContentComponent
	// FilterUniqueQuestionPipe
	],
	imports: [
		CommonModule,
		RichTextEditorAllModule,
		FormsModule
	],
	exports: [
		SafeHtmlPipe,
		FolderFilterPipe,
		InfiniteScrollComponent,
		TruncatePipe,
		MyFilterPipe,
		ReversePipe,
		FilterUniqueNamePipe,
    SafeResourcePipe,
	OrderByPipe,
	ConvertFrom24To12FormatPipe,
	MailContentComponent
	// FilterUniqueQuestionPipe
	]
})
export class SharedPipesModule { }
