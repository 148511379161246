import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { MessagingService } from '../firebase/messaging.service';
import { async } from 'rxjs/internal/scheduler/async';
import { NavService } from '../nav.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	user: any = { email: '', password: '' };
	year: number = new Date().getFullYear();
	ScreenHeight: number = undefined;
	loadershow: boolean = false;
	loginErrorshow: boolean = false;
	checkCapsLock: boolean = false;
	loginFormErr: string = "";
	sessionExpsubscription:Subscription;
	version = environment.version;
	constructor(
		private apiService: ApiService,
		private router: Router,
		private messagingService: MessagingService,
		private zone: NgZone
	) {

	}

	ngOnInit(): void {
		if (localStorage.getItem('tokenKey') != null && localStorage.getItem('tokenKey') != undefined) {
			this.messagingService.receiveMessage();
			localStorage.getItem('userTypeName') == "Pharmacist" ? this.router.navigate(['/app/admin/listVessels']) : this.router.navigate(['/app/eventList']);
		}
		setTimeout(() => {
			this.ScreenHeight = (window.innerHeight);
		}, 0);
		/* var emailInput = document.getElementById("email");
		var passwordInput = document.getElementById("password");
		emailInput.addEventListener("keyup", (event) => {
			if (event.getModifierState("CapsLock")) {
				this.checkCapsLock = true;
			} else {
				this.checkCapsLock = false;
			}
		});
		passwordInput.addEventListener("keyup", (event) => {
			if (event.getModifierState("CapsLock")) {
				this.checkCapsLock = true;
			} else {
				this.checkCapsLock = false;
			}
		}); */
		this.sessionExpsubscription=this.apiService.getSessionExpMessage().subscribe(()=>{
			this.loginErrorshow = true;
			this.loginFormErr = "Session Expired.";
				setTimeout(() => {
					this.loginErrorshow = false;
				}, 5000);
		})
	}

	submitForm(user) {
		this.loadershow = true;
		this.apiService.loginAPI(user).subscribe(
			data => {
				localStorage.setItem('tokenKey', "Bearer " + data["token"]);
				localStorage.setItem('OwnerId', data["userId"]);
				localStorage.setItem('userTypeName', data["userTypeName"]);
				localStorage.setItem('firstName', data["firstName"]);
				localStorage.setItem('lastName', data["lastName"]);
				localStorage.setItem('userAccess', data["userAccess"]);
				localStorage.setItem('userEmail', data["email"]);
				localStorage.setItem('userSign', data["user_sign"]);
				localStorage.setItem('fireString', data["fireString"]);
				var chatArray = [];
				localStorage.setItem('chatNotify', JSON.stringify(chatArray));
        		localStorage.getItem('userTypeName') == 'Pharmacist' ? this.router.navigate(['/app/admin/listVessels']) : this.router.navigate(['/app/eventList']);
			},(error) => {
				this.loadershow = false;
				this.loginErrorshow = true;
				this.loginFormErr = "Email-Id Or Password is incorrect.";
				setTimeout(() => {
					this.loginErrorshow = false;
				}, 5000);
			}
		);
	}

  ngOnDestroy() {
    this.sessionExpsubscription.unsubscribe();
  }

}

