<!-- <form role="form" style="background-image:'url(../assets/img/cube_bg.png)';height:{{ScreenHeight}};" class="mb-lg"  (ngSubmit)="submitForm(user)"> -->
   <form role="form" [ngStyle]="{'background-image':'url(../assets/img/cube_bg.png)','height':ScreenHeight+'px'}" class="mb-lg"  (ngSubmit)="submitForm(user)">
      <div *ngIf="loadershow" class="loader text-center">
         <img src="../assets/img/cc_loader.gif" />
      </div>
      <div class="container container-sm animated fadeInDown">
         <div class="center-block mt100">
            <div style="margin-left: 10%;background-color: #ffffffb3;" class="col-sm-9">
               <!-- START panel-->
               <div class="text-center mt100">
                  <img src="../assets/img/easy-cube.png" alt="Image" />
               </div>
               <fieldset class="mt20">
                  <div class="col-sm-12 form-group">
                     <div>
                        <div style="box-shadow: 2px 2px 2px #a69e9e;" class="input-group m-b">
                           <span class="input-group-addon labelColor">Email-ID &nbsp;&nbsp;</span>
                           <input type="email" placeholder="Email-ID" name="email" id="email" class="form-control input_focus input-xs" [(ngModel)]="user.email" required/>
                        </div>
                        <br/>
                     </div>
                  </div>
                  <div class="col-sm-12 form-group">
                     <div style="box-shadow: 2px 2px 2px #a69e9e;" class="input-group m-b">
                        <span class="input-group-addon labelColor">Password &nbsp;</span>
                        <input required type="password" name="password" id="password" placeholder="Password" [(ngModel)]="user.password" class="form-control input_focus input-xs" />
                     </div>
                     <div id="frgtPassId" [routerLink]="['/recover']" class="pull-right text-bold text-info" style="cursor: pointer;">Forgot Password?</div>
                     <div *ngIf="checkCapsLock" class="fs_15em pull-right text-warning text-bold">
                        <br/><i class="fa fa-exclamation-triangle text-warning"></i> Caps Lock is on</div>
                     <br/>
                  </div>
                  <div class="col-sm-12 form-group">
                     <div>
                        <button type="submit" class="btn labelColor btn-oval col-sm-12">Log-In</button>
                     </div>
                  </div>
                  <div class="col-sm-12 text-center text-black text-bold mt-lg">Powered By<a href="http://3cubeservices.com/" target="_blank" class="text-black">&nbsp;3CUBE</a> &copy; {{year}} </div>
                  <div class="text-center"><a href="mailto:support@3cubeservices.com?Subject=Support" target="_top" class="text-info text-center text-bold">Help Desk</a>
                  </div>
                  <div class="col-sm-12 text-center text-black">V{{version}}</div>
                  <div class="text-center text-bold"></div>
               </fieldset>
               <div *ngIf="loginErrorshow" role="alert" type="danger" class="alert ng-isolate-scope alert-danger alert-dismissible">
                  <button type="button" class="close">
                     <span class="sr-only">Close</span>
                  </button>
                  <div class="text-center">
                    <span class="ng-binding ng-scope">{{loginFormErr}}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </form>
   