import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class LazyLoadScriptService {

  constructor(
    @Inject(DOCUMENT) private document: any,
  ) { }

  loadStyle(styleName: string) {
		const head = this.document.getElementsByTagName('head')[0];
	
		let themeLink = this.document.getElementById(
		  styleName
		) as HTMLLinkElement;
		if (themeLink) {
		  themeLink.href = styleName;
		} else {
		  const style = this.document.createElement('link');
		  style.id = styleName;
		  style.rel = 'stylesheet';
		  style.href = `${styleName}`;
	
		  head.appendChild(style);
		}
	  }

    loadScript(scriptUrl: string){
      return new Promise(resolve => {
        const head = this.document.getElementsByTagName('head')[0];
	
        let themeLink = this.document.getElementById(
          scriptUrl
        ) as HTMLScriptElement;
        if (themeLink) {
          themeLink.src = scriptUrl;
        } else {
          const scriptElement = document.createElement('script');
        scriptElement.src = scriptUrl;
        scriptElement.onload = resolve;
        head.appendChild(scriptElement);
        }
        
      });
    }
}
