export const environment = {
	production: false,
	API_GENERAL_URL: 'https://hc-api.3cubehealth.com/',
	File_Storage_URL: 'https://backend-content-storage.s3.ap-south-1.amazonaws.com/HC_Staging/',
	smpas_url: 'https://smr-api.3cubehealth.com/',
	health_passport_url:"https://backend-content-storage.s3.ap-south-1.amazonaws.com/HP/",
	firebaseConfig : {
		apiKey: "AIzaSyB8nPDxTJGmW6YMzwvGdtUcFZwg25Xeba0",
		authDomain: "videocall-staging.firebaseapp.com",
		databaseURL: "https://videocall-staging.firebaseio.com",
		projectId: "videocall-staging",
		storageBucket: "videocall-staging.appspot.com",
		messagingSenderId: "251343964324",
		appId: "1:251343964324:web:339ea2743c66a8f3f0be31"
	},
	firebaseLogin:{
		username:"dummyfirebase@test.com",
		password:"FirePass@88"
	},
	firebaseJSFile:"firebase-messaging-sw-staging.js",
	pusherConfig : {
		PUSHER_API_KEY: '1e5e47f4621a5073264a',
		PUSHER_API_CLUSTER: 'ap2'
	},
	version:'3.0.2',
	email_id:'helpdesk@emails.3cubehealth.com',
	agoraAppID:'1e6b26f2e5664603bd1fee038bae4dca',
	symptomModuleId:178,
	audioVideoCallReasonId:"50"
};