<form role="form" [ngStyle]="{'background-image':'url(../assets/img/cube_bg.png)','height':ScreenHeight+'px','background-repeat': 'round'}" class="mb-lg" [formGroup]="recoverForm" #recform="ngForm" (ngSubmit)="submitForm()">
	<div class="container container-sm animated fadeInDown">
		<div class="center-block mt100">
			<div style="margin-left: 10%;background-color:white;" *ngIf="forgotDiv" class="col-sm-10">
				<h2 style="margin-top: 20px;">Forgot your password?</h2>
				<div style="line-height: 2;" class="mt-lg fs_14">Please enter your registered email address.<br/>We will send a verification email and link to reset the password.</div>
				<div class="mt20">
					<div class="row">
						<div class="col-md-8">
							<div class="form-group has-feedback">
								<input id="resetInputEmail1" type="text" formControlName="email" placeholder="Your email" autocomplete="off" required="" class="form-control" />
								<span class="fa fa-envelope form-control-feedback text-muted"></span>
							</div>
							<small *ngIf="f.email.invalid && recform.submitted" style="color: maroon;">
								<span *ngIf="f.email.errors.required">
									Please enter email.
								</span>
								<span *ngIf="f.email.errors.pattern">
									Please enter valid email! Eg. demo@mail.com
								</span>
							</small>
						</div>
						<div class="col-md-4">
							<button id="sendResteId" [disabled]="loadershow" type="submit" class="btn btn-danger btn-block"><i *ngIf="loadershow" aria-hidden="true" class="fa fa-spinner fa-spin"></i> Send</button>
						</div>
					</div>
				</div>
				<div *ngIf="resetPasswordError" class="col-sm-12">
					<div class="text-center">
						<h5 class="text-danger">{{resetPasswordError}}</h5>
						<div class="text-danger">Please check the email address, or contact our technical support team at support@3cubeservices.com</div>
					</div>
				</div>
				<div class="col-sm-12 text-center text-black text-bold mt-lg">Powered By<a href="http://3cubeservices.com/" target="_blank">3CUBE</a> © {{year}}</div>
			</div>
			<div style="margin-left: 10%;background-color:white;" *ngIf="resetDiv" class="col-sm-10">
				<h2 style="margin-top: 20px;">Reset your password</h2>
				<div style="line-height: 2;" class="mt-lg fs_14">We have sent a reset password email to
					<strong>{{f.email.value}}</strong>. Please click the reset password link to set your new password.
				</div>
				<div style="line-height: 2;" class="mt-lg fs_14">Didn't receive an email yet?</div>
				<div style="line-height: 2;" class="fs_14">Please check your spam folder, or Send us an email from your registered email address at<strong>support@3cubeservices.com</strong>.</div>
				<div class="col-sm-12 text-center text-black text-bold mt-lg">Powered By<a href="http://3cubeservices.com/" target="_blank">3CUBE</a> © {{year}}</div>
			</div>
		</div>
	</div>
</form>
