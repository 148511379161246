import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ShareDataService } from '../share-data.service';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
//import { MessagingService } from '../firebase/messaging.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit, OnDestroy {

  link: SafeResourceUrl;
  vcLoader = false;
  constructor(
    private shareData: ShareDataService,
    public sanitizer: DomSanitizer,
    //private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    this.link = this.shareData.videoLink;
    this.vcLoader = true;
  }

  ngOnDestroy() {
    //this.messagingService.removeVesselUserCall(this.shareData.currentVesselId);
   // this.messagingService.removeDoctorUserCall();
    this.shareData.currentVesselId = '';
    this.shareData.videoLink = null;
  }

  iframeLoadEvent() {
    this.vcLoader = false;
  }

}
