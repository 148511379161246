import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService } from '../../api.service';
import { MatSelect } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { EventEmitter, Output} from '@angular/core';
import { timer } from 'rxjs';
import * as _ from 'lodash';
declare var $: any;
import { debounceTime, map, distinctUntilChanged, filter, take, takeUntil, switchMap, catchError } from "rxjs/operators";
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
	selector: 'app-setup',
	templateUrl: './setup.component.html',
	styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {
	breadcrumbList = [
		{
			path:"/app/eventList",
			name:"Home"
		},
		{
			path:"/app/questionSetup",
			name:"Question Master"
		}
	];
	moduleAccess;
	questionMasterList:any=[];
	newModule:any = {};
	qnCategoryActive:any = false;
	colorList = ['amber', 'info', 'purple', 'success', 'danger', 'primary'];

	constructor(
		private toastr: ToastrService,
		private apiService: ApiService,
		private router: Router,
		private activeRoute: ActivatedRoute,
		private location:Location,
		private formBuilder: FormBuilder
	) { }
	
	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.moduleAccess = JSON.parse(localStorage.getItem("userAccess")).question_master;
			this.loadQuestionMasters();
		});
	}

	loadQuestionMasters(): void {
		this.apiService.loadQuestionMasters().subscribe(
			data => {
				this.questionMasterList = data;
			},
			(error)=>{
				this.questionMasterList = [];
			}
			);
	}

	editModule(index){
		this.newModule = {};
		this.addNewQuestionCategory();
		this.newModule = this.questionMasterList[index];
	}

	deleteModule(id,index){
		Swal.fire({
			title: 'Warning !!!',
			text: 'Are you sure you want to delete Module?',
			icon: 'warning',
			type: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: 'rgb(221, 107, 85)',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.apiService.removeQuestionModule(id).subscribe(
					data => {
						this.questionMasterList.splice(index, 1);
						Swal.fire(
							'Deleted',
							'Module Deleted Successfully.',
							'success'
							);
					},
					(error)=>{
						this.questionMasterList = [];
					}
					);
				}
		})
	}

	addNewQuestionCategory(){
		this.newModule = {};
		this.qnCategoryActive = 'addnew';
	}

	closeNewModule() {
		this.newModule = {};
		this.qnCategoryActive = false;
	};

	addNewModule(){
		if (this.newModule.id) {
			this.apiService.updateQuestionModule(this.newModule).subscribe(
				data => {
					this.qnCategoryActive = false;
					this.toastr.success(data.toString(), 'Success!',{timeOut:3000,tapToDismiss: true,closeButton: true});
				},
				(error)=>{
					this.toastr.error(error, 'Error',{timeOut:3000,tapToDismiss: true,closeButton: true});
				}
				);
		} else {
			this.apiService.addQuestionModule(this.newModule).subscribe(
				data => {
					this.newModule.id = data["id"];
					this.questionMasterList.push(this.newModule);
					this.qnCategoryActive = false;
					this.toastr.success("Group Added Successfully", 'Success!',{timeOut:3000,tapToDismiss: true,closeButton: true});
				},
				(error)=>{
					this.toastr.error(error, 'Error',{timeOut:3000,tapToDismiss: true,closeButton: true});
				}
				);
		}
	}
}
