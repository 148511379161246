import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { Subscription } from 'rxjs';
import { MessagingService } from '../firebase/messaging.service';
import { NavService } from '../nav.service';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, MarkdownEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../shared/format-datepicker';
declare var $: any;

@Component({
	selector: 'app-headnavbar',
	templateUrl: './headnavbar.component.html',
	styleUrls: [
		'./headnavbar.component.css'
	],
	providers: [
		ToolbarService, LinkService, ImageService, HtmlEditorService,MarkdownEditorService, TableService, { provide: DateAdapter, useClass: AppDateAdapter },{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
	],
})
export class HeadnavbarComponent implements OnInit {
	folloUpList: any = [];
	userTypeName: string = localStorage.getItem('userTypeName');
	userName: string = localStorage.getItem('firstName') + " " + localStorage.getItem('lastName');
	videoChannelAccess: string = "";
	channel: any;
	DispalylastReplyDetails = false;
	lastReplyDetails: any = { id: null, uniqueId: null };
	chatNotiList: any = [];
	message;
	subscription;
	notificationViewedsubscription: Subscription;
	currYear: any = (new Date()).getFullYear();
	projectVersion: any = environment.version;
	followUpRefresh: Subscription;
	followupLoader:boolean = false;
	doneLoader:any = null;
	displayToken: string;
	globalEmergencyDefaultText = "Please call the senior doctor";
	handoverNotes:any = null;
	public tools2: object = {
		items: ['Bold', 'Italic']
	};
	handoverMaxLength = 150;
	handoverList:any = [];
	emergencyNoteForm:FormGroup;
	deleteHandoverLoader:any = null;
	editHandoverLoader:any = null;
	createHandoverLoader:boolean = false;
	fetchHandoverLoader:boolean = false;
	sendHandoverLoader:boolean = false;
	followupcnt: number = 0;
	constructor(private apiService: ApiService, private messagingService: MessagingService, private router: Router, private zone: NgZone, public nav: NavService, private toastr: ToastrService, private fb: FormBuilder) {
		this.notificationViewedsubscription = this.apiService.getNotiViewEvent().subscribe(() => {
			this.chatNotiList = [];
		})
		this.followUpRefresh = this.apiService.getFollowUpRefreshEvent().subscribe(() => {
			this.caseFollowUpList();
		})
		this.messagingService.receiveMessage();
		this.chatNotiList = JSON.parse(localStorage.getItem('chatNotify'));
		setTimeout(() => {
			this.subscription = this.messagingService.currentMessage.subscribe(data => {
				this.zone.run(() => {
					if (data) {
						var a = this.chatNotiList;
						if (a && a !== null) {
							a.push(data.data);
							this.chatNotiList = a;
							localStorage.setItem('chatNotify', JSON.stringify(this.chatNotiList));
						}
					}
				});
			});
		}, 5000);
		this.emergencyNoteForm = this.fb.group({
			emergencyNote: [this.globalEmergencyDefaultText, Validators.required],
		})
	}
	
	submitEmergencyNote() {
		var payload = {
			notes: this.emergencyNoteForm.get('emergencyNote').value,
		}
		this.apiService.postGlobalEmergencyNote(payload).subscribe((res) => {
			this.toastr.success("Emergency Note saved successfully.", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true,
			});
			
			this.emergencyNoteForm.setValue({
				emergencyNote: this.globalEmergencyDefaultText,
			});
			
			this.closeEmergencyModel();
		},(err) => {
			this.toastr.error("Something went wrong.", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		})
	}
	
	openEmergencyModel() {
		$("#GlobalEmergencyModal").modal("show");
	}
	
	closeEmergencyModel() {
		$("#GlobalEmergencyModal").modal("hide");
	}
	
	ngOnInit(): void {
		this.caseFollowUpList();
		this.messagingService.requestPermission().subscribe((token) => {
			localStorage.setItem('deviceToken', token);
			this.messagingService.register(localStorage.getItem('userEmail'), localStorage.getItem('fireString'), token)/* .then((data) => {
				this.messagingService.checkUserData(localStorage.getItem('userEmail')).then((isUser) => {
					if (isUser) {
						this.messagingService.updateDataToDb(token, localStorage.getItem('OwnerId')).then(res => {});
					} else {
						this.messagingService.addMsgCount(localStorage.getItem('OwnerId'));
						this.messagingService.addUserDoctor(localStorage.getItem('OwnerId'), token, localStorage.getItem('userEmail'));
						this.messagingService.addDataToDb(localStorage.getItem('userEmail'), token, localStorage.getItem('OwnerId')).then((done) => {});
					}
				}).catch((newErr) => {
				})
			}) */
		}, (error) => {});
	}
	
	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		this.notificationViewedsubscription.unsubscribe();
		this.followUpRefresh.unsubscribe();
	}
	
	logoutUser() {
		localStorage.clear();
		this.router.navigate(['/login']);
		/* this.apiService.userLogOut().subscribe((data) => {
			localStorage.clear();
			this.router.navigate(['/login']);
		}, (error) => {
			localStorage.clear();
			this.router.navigate(['/login']);
		}) */
	}

	getFollowUpCount() {
		/* this.apiService.getFollowupCount().subscribe(
			(data:any) => {
				this.followupcnt = data.count;
			},
			(error) => {

			}
		); */
	}
	
	caseFollowUpList() {
		/* this.followupLoader = true;
		let params = {
			limit: 10,
			page: 0,
			search: "",
			type: "pending",
		}
		this.apiService.caseFollowUpList(params).subscribe(data => {
			this.followupLoader = false;
			this.folloUpList = data;
		}, (error) => {
			this.followupLoader = false;
			this.folloUpList = [];
		});
		this.getFollowUpCount(); */
	}
	
	doneFollowup(event_id,id, index) {
		Swal.fire({
			title: '',
			text: 'Are you sure you want to change the status?',
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes',
			closeOnConfirm: false,
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.doneLoader = index;
				this.apiService.doneFollowUp({ eventId: event_id,id:id }).subscribe(data => {
					this.doneLoader = null;
					this.toastr.success("Saved successfully", 'Success', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
					this.caseFollowUpList();
				}, (error) => {
					this.doneLoader = null;
					this.toastr.error("Something went wrong", 'Error', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}
	
	closeHandoverModel(){
		$("#handoverModal").modal("hide");
	}
	
	createHandover(){
		if(!this.handoverNotes){
			this.toastr.error("Please enter something", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			$("#new_handover_notes").focus();
			return;
		}
		this.createHandoverLoader = true;
		this.apiService.createHandover({handover_notes:this.handoverNotes}).subscribe(data => {
			this.createHandoverLoader = false;
			this.getHandoverList();
			this.handoverNotes = null;
			this.toastr.success("Added successfully", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		}, (error) => {
			this.createHandoverLoader = false;
			this.toastr.error("Something went wrong", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	updateHandover(index:number){
		if(!this.handoverList[index].handover_notes){
			this.toastr.error("Please enter something", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			$("#handover_notes"+index).focus();
			return;
		}
		this.editHandoverLoader = index;
		this.apiService.updateHandover({handover_notes:this.handoverList[index].handover_notes},this.handoverList[index].id).subscribe(data => {
			this.editHandoverLoader = null;
			this.getHandoverList();
			this.toastr.success("Updated successfully", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		}, (error) => {
			this.editHandoverLoader = null;
			this.toastr.error("Something went wrong", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	openHandoverModel(){
		$("#handoverModal").modal("show");
		this.getHandoverList();
	}

	deleteHandover(index:number){
		Swal.fire({
			title: '',
			text: 'Are you sure you want to delete handover note?',
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes',
			closeOnConfirm: false,
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.deleteHandoverLoader = index;
				this.apiService.deleteHandover(this.handoverList[index].id).subscribe(data => {
					this.deleteHandoverLoader = null;
					this.toastr.success("Notes deleted successfully", 'Success', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
					this.getHandoverList();
				}, (error) => {
					this.deleteHandoverLoader = null;
					this.toastr.error("Something went wrong", 'Error', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}

	toggleEditHandover(index:number){
		if(this.handoverList[index].isEdit==undefined){
			this.handoverList[index].isEdit = false;
		}
		this.handoverList[index].isEdit = !this.handoverList[index].isEdit;
	}

	getHandoverList(){
		this.fetchHandoverLoader = true;
		this.apiService.getAllHandovers().subscribe((data:any) => {
			this.fetchHandoverLoader = false;
			data = data.sort((a,b)=>b.id - a.id);
			this.handoverList = data;
			for (let i = 0; i < this.handoverList.length; i++) {
				this.handoverList[i].isEdit = false;
			}
		}, (error) => {
			this.fetchHandoverLoader = false;
			this.handoverList = [];
		});
	}

	sendHandover(){
		Swal.fire({
			title: '',
			text: 'Are you sure you want to send handover?',
			showCancelButton: true,
			confirmButtonColor: '#DD6B55',
			confirmButtonText: 'Yes',
			closeOnConfirm: false,
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.sendHandoverLoader = true;
				this.apiService.sendHandover().subscribe(data => {
					this.sendHandoverLoader = false;
					Swal.fire('Success','Handover sent successfully','success');
					this.getHandoverList();
				}, (error) => {
					this.sendHandoverLoader = false;
					Swal.fire('Error','Something went wrong.','error');
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}

	GetDateStringFromPickerDate1(date) {
		if (date == "" || date == null || date == undefined) {
			return null;
		}
		var dateStr = date.toString();
		var dateObj = new Date(dateStr);
		var month = dateObj.getMonth() + 1;
		month = this.checkZero(month);
		var day = dateObj.getDate();
		day = this.checkZero(day);
		var year = dateObj.getFullYear();
		var hour = dateObj.getHours();
		hour = this.checkZero(hour);
		var min = dateObj.getMinutes();
		min = this.checkZero(min);
		var newDate = day + "-" + month + "-" + year + " " + hour + ":" + min;
		return newDate;
	}

	checkZero(data) {
		if (data != undefined && data.toString().length == 1) {
			data = "0" + data;
		}
		return data;
	}
}
