import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
	name: 'filterUniqueName',
	pure: false
})
export class FilterUniqueNamePipe implements PipeTransform {
	transform(value: any): any{
		if(value!== undefined && value!== null){
			return _.uniqBy(value, 'uname');
		}
		return value;
	}
}