import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
//import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LoginComponent } from './login/login.component';
import { RefreshComponent } from './refresh/refresh.component';
//import { MailboxComponent } from './mailbox/mailbox.component';
import { HeadnavbarComponent } from './headnavbar/headnavbar.component';
//import { MailboxsidemenuComponent } from './mailboxsidemenu/mailboxsidemenu.component';
//import { MailboxviewmailComponent } from './mailboxviewmail/mailboxviewmail.component';
//import { MailboxcomposeComponent } from './mailboxcompose/mailboxcompose.component';
import { EventlistComponent } from './eventlist/eventlist.component';


import { Router } from "@angular/router";
// import * as Sentry from "@sentry/angular" // for Angular 10/11 instead
import * as Sentry from "@sentry/angular";





import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';


import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollEventModule } from 'ngx-scroll-event';


import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { TagInputModule } from 'ngx-chips';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
//import {  TruncatePipe }   from './app.pipe';
//import {  MyFilterPipe }   from './shared/pipes/my-filter.pipe';
//import {  FolderFilterPipe }   from './shared/pipes/folder-filter.pipe';
import { MatChipsModule } from '@angular/material/chips';


import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SignaturePadModule } from 'angular2-signaturepad';

import { TabModule } from 'angular-tabs-component';

import { environment } from 'src/environments/environment';
/*import { AutocompleteLibModule } from 'angular-ng-autocomplete';*/
import { AddEventComponent } from './add-event/add-event.component';
//import { EventDetailsComponent } from './event-details/event-details.component';
import { InPlaceEditorModule } from '@syncfusion/ej2-angular-inplace-editor';
import { NgxFileDropModule } from 'ngx-file-drop';
import { EventallComponent } from './eventall/eventall.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { AutoCompleteModule } from "@syncfusion/ej2-angular-dropdowns";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppInterceptorService } from './app-interceptor.service';
import { RecoverComponent } from './recover/recover.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { UrlSerializerService } from './url-serializer.service';
import { UrlSegment, UrlSerializer } from '@angular/router';
/* import { AddMailFolderComponent } from './add-mail-folder/add-mail-folder.component'; */
import { VideoComponent } from './video/video.component';
import { SafePipe } from './safe.pipe';
// import { ReversePipe } from './reverse.pipe';



import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
//import { TreeModule } from '@circlon/angular-tree-component';
import { ClipboardModule } from 'ngx-clipboard';
//import {  SafeHtmlPipe }   from './shared/pipes/safe-html.pipe';

//import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
//import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
//import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
//import * as moment from 'moment';
import { MatTimepickerModule } from 'mat-timepicker';
import { WebcamModule } from 'ngx-webcam';



//import {DpDatePickerModule} from 'ng2-date-picker';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';

import { SharedPipesModule } from './shared-pipes.module';
import { SetupComponent } from './question-master/setup/setup.component';

import { AngularFireModule } from '@angular/fire';
// import { SafeResourcePipe } from './safe-resource.pipe';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { MessagingService } from './firebase/messaging.service';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { DatePipe } from '@angular/common';

//import { ServiceWorkerModule } from '@angular/service-worker'
// import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import {ScrollingModule} from '@angular/cdk/scrolling';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        //MailboxComponent,
        HeadnavbarComponent,
        //MailboxsidemenuComponent,
        //MailboxviewmailComponent,
        //MailboxcomposeComponent,
        //TruncatePipe,
        //MyFilterPipe,
        //FolderFilterPipe,
        EventlistComponent,
        //InfiniteScrollComponent,
        RefreshComponent,


        //BreadcrumbComponent,
        AddEventComponent,
        //EventDetailsComponent,
        EventallComponent,
        CreateEventComponent,
        RecoverComponent,
        ResetPasswordComponent,
        /* AddMailFolderComponent, */

        VideoComponent,
        SafePipe,

        // ReversePipe,

        //SafeHtmlPipe,


        SetupComponent,
    ],
    imports: [
        // TypeaheadModule,
        CommonModule,
        ReactiveFormsModule,
        BrowserModule,
        HttpClientModule,
        NgSelectModule,
        FormsModule,
        InfiniteScrollModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        ScrollEventModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        MatAutocompleteModule,
        MatInputModule,
        MatRadioModule,
        MatDialogModule,
        ToastrModule.forRoot(),
        NgbModule,
        TagInputModule,
        RichTextEditorAllModule,
        MatChipsModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        SignaturePadModule,
        TabModule,
        // AngularFireModule.initializeApp(environment.firebaseConfig),
        // AngularFireDatabaseModule,
        /*AutocompleteLibModule,*/
        InPlaceEditorModule,
        AutoCompleteModule,
        NgxFileDropModule,
        NgxPageScrollCoreModule.forRoot({ duration: 100 }),
        //TreeModule,
        ClipboardModule,

        //NgxDaterangepickerMd.forRoot(),
        // NgxMatTimepickerModule,
        // NgxMatDatetimePickerModule,
        // NgxMatNativeDateModule,
        // NgxMatMomentModule,
        MatTimepickerModule,
        WebcamModule,
        //DpDatePickerModule,
        MatTableModule,
        MatListModule,
        AppRoutingModule,
        SharedPipesModule,

        AngularFireModule.initializeApp(environment.firebaseConfig),
        // AngularFireDatabaseModule,
        // AngularFireMessagingModule,
        //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ScrollingModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
          },
        {
        provide: UrlSerializer,
        useClass: UrlSerializerService
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: AppInterceptorService,
        multi: true
    },
        //   MessagingService
        DatePipe
    ],
    exports: [
        SafePipe
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule);
