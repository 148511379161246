import { Component } from '@angular/core';
import { LazyLoadScriptService } from './lazy-load-script/lazy-load-script.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  title = 'healthcubeNG';
  constructor(
    private lazyLoadService: LazyLoadScriptService
		) {
      /* this.lazyLoadService.loadScript('sweetalert2JS.js').then(() => {}).catch(() => {}); */
      
      this.lazyLoadService.loadStyle('bootstrap.css');
      this.lazyLoadService.loadStyle('toastr.css');
      this.lazyLoadService.loadStyle('styles.css');
      this.lazyLoadService.loadStyle('styles1.css');
      this.lazyLoadService.loadStyle('font-awesome-min.css');
      this.lazyLoadService.loadStyle('font-awesome.css');
      this.lazyLoadService.loadStyle('feather.css');
      this.lazyLoadService.loadStyle('summernote.css');
      this.lazyLoadService.loadStyle('ng-select-default-theme.css');
      this.lazyLoadService.loadStyle('bs-datepicker.css');
      this.lazyLoadService.loadStyle('syncfusion-material.css');
      this.lazyLoadService.loadStyle('perfect-scrollbar.css');
      this.lazyLoadService.loadStyle('sweetalert2.css');
      
	}
}
