import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  public videoLink : any;
  public currentVesselId : any;
  constructor() { }
}
