import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService } from '../api.service';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { EventEmitter, Output } from '@angular/core';
import { FileQueueObject, FileuploadService } from '../fileupload.service';
import * as _ from 'lodash';
declare var $: any;
import { debounceTime, map, distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";
import * as FileSaver from 'file-saver';
//import { MessagingService } from '../firebase/messaging.service';
import { NavService } from '../nav.service';
import { environment } from '../../environments/environment';
//declare const Pusher: any;
import { DOCUMENT } from '@angular/common';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../shared/format-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
	selector: 'app-eventlist',
	templateUrl: './eventlist.component.html',
	styleUrls: [
		'./eventlist.component.css',
		//'../../assets/vendor/mailbox/fontawesome-free/css/all.min.css',
		//'../../assets/vendor/mailbox/icheck-bootstrap/icheck-bootstrap.min.css',
	],
	providers: [
		{ provide: DateAdapter, useClass: AppDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
	]
})
export class EventlistComponent implements OnInit, AfterViewInit {
	moduleAccess;
	replymoduleAccess;
	spammoduleAccess;
	mailFoldermoduleAccess;
	unreadCnt: number = 0;
	unlinkedCnt: number = 0;
	alleventcnt: number = 0;
	openEventcnt: number = 0;
	followupcnt: number = 0;
	eventlist: any = [];
	isListFinished: boolean = false;
	isLoading: boolean = false;
	userTypeName: string = localStorage.getItem('userTypeName');
	tableparams = {
		search: "",
		page: 0,
		limit: 20,
		category: "Open",
		flag: null,
		column: "",
		sortType: "",
		isReplied: false,
		doctor:null,
		tags:[],
		mark_for_closure:false
	};

	formCtrlSub: Subscription;
	searchControl = new FormControl();
	excelDownloadLoader = false;
	excelDownloadParams = { category: 'Open', flag: '', column: '', sortType: '', search: '', isReplied: false, tags:[],mark_for_closure:false, doctor:null };

	//	unlinkCountIncrementSubscription:Subscription;
	//	unreadCountIncrementSubscription:Subscription;
	notificationId;
	selectedMessage: any;
	//pusher: any;
	channel: any;

	getOneDayBeforeDate(){
		var d = new Date();
		d.setDate(d.getDate() - 1);
		return d;
	}
	analyticsPayload:any = {
		fromDate : this.getOneDayBeforeDate(),
		fromTime : this.getOneDayBeforeDate(),
		toDate : new Date(),
		toTime : new Date(),
		case_status : null,
		doctor_id : null
	}
	analyticsError:String = "";
	showLoader = false;
	statusList = [
		{id:"Open",name:"Open"},
		{id:"Reopen",name:"Reopen"},
		{id:"Repatriation",name:"Repatriation"},
		{id:"Closed",name:"Closed"}
	];
	tagMaster:any = [];
	flagList = [
		/* {id:null,name:"Show All"}, */
		{id:5,name:"CODE BLUE",class:"fa fa-square mr-sm text-primary"},
		{id:1,name:"Unassigned",class:"fa fa-flag mr-sm text-black"},
		{id:4,name:"Emergency",class:"fa fa-flag mr-sm text-danger"},
		{id:2,name:"Routine",class:"fa fa-flag mr-sm text-warning"},
		{id:3,name:"Improving",class:"fa fa-flag mr-sm text-success"}
	];

	constructor(
		private toastr: ToastrService,
		private apiService: ApiService,
		private router: Router,
		private activeRoute: ActivatedRoute,
		private location: Location,
		public uploader: FileuploadService,
		@Inject(DOCUMENT) private document: any,
	) {
		// this.loadScript('pusher.js').then(() => {
		// 	this.pusher = new Pusher(environment.pusherConfig.PUSHER_API_KEY, {
		// 		cluster: environment.pusherConfig.PUSHER_API_CLUSTER,
		// 		encrypted: true
		// 	  });
		// 	  this.channel = this.pusher.subscribe('NewMail.arrived');
		// 	  this.channel.bind('pusher:subscription_succeeded', () => {
		// 		this.channel.bind('App\\Events\\NewMail', (data) => {
		// 		  this.loadMailCounts();
		// 		});
		// 	  });
		// }).catch(() => {});

	}

	loadScript(scriptUrl: string) {
		return new Promise(resolve => {
			const head = this.document.getElementsByTagName('head')[0];

			let themeLink = this.document.getElementById(
				scriptUrl
			) as HTMLScriptElement;
			if (themeLink) {
				themeLink.src = scriptUrl;
			} else {
				const scriptElement = document.createElement('script');
				scriptElement.src = scriptUrl;
				scriptElement.onload = resolve;
				head.appendChild(scriptElement);
			}

		});
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			if (routeParams["search"] != undefined) {
				this.tableparams.search = routeParams["search"];
				this.searchControl.setValue(routeParams["search"]);
				this.excelDownloadParams = { category: 'Open', flag: '', column: '', sortType: '', search: routeParams["search"], isReplied: false, tags:[],mark_for_closure:false, doctor:null };
				this.tableparams.page = 0;
				this.tableparams.tags = [];
				this.tableparams.mark_for_closure = false;
				this.tableparams.doctor = null;
				this.isListFinished = false;
				this.eventlist = [];
			}
			this.moduleAccess = JSON.parse(localStorage.getItem("userAccess")).Events;
			this.replymoduleAccess = JSON.parse(localStorage.getItem("userAccess")).Reply_mail;
			this.spammoduleAccess = JSON.parse(localStorage.getItem("userAccess")).Mark_spam;
			this.mailFoldermoduleAccess = JSON.parse(localStorage.getItem("userAccess")).Mail_folder;
			this.loadMailCounts();
			this.allEventCount();
			this.getEventList();
			this.fetchTags();
			this.getDoctorList("");
			this.getFollowUpCount();
		});
	}

	ngAfterViewInit(): void {
		this.formCtrlSub = this.searchControl.valueChanges
			.debounceTime(1000)
			.subscribe(newValue => {
				if (newValue) {
					this.router.navigate(['/app/eventList', newValue]);
				} else {
					this.router.navigate(['/app/eventList']);
				}
				/*this.excelDownloadParams={category:'Open',flag:'',column:'',sortType:'',search:newValue};
				this.tableparams.page = 0;
				this.isListFinished = false;
				this.eventlist = [];
				this.getEventList();*/
			});
	}

	fetchTags(){
		this.apiService.getTagSuggestions().subscribe((data:any) => {
			this.tagMaster = data;
			/* for (var i = 0; i < data.length; ++i) {
				this.tagMaster.push({display:data[i],value:data[i]});
			} */
		},(error)=>{
		});
	}

	findCaseData(id) {
		this.apiService.getEventData({ id: id }).subscribe(
			data => {
				if (data["CaseData"]) {
					this.eventlist.unshift(data["CaseData"]);
				}
				else if (data["IdData"]) {
					this.notificationId = data["IdData"]["eventId"];
					//this.excelDownloadParams={category:'Open',flag:'',column:'',sortType:'',search:""};
					//this.tableparams.page = 0;
					this.isListFinished = false;
					//this.tableparams.search = "";
					//this.eventlist = [];
					this.getEventList();
				}
			},
			(error) => {

			}
		);
	}

	loadMailCounts() {
		this.apiService.getMailBoxCount().subscribe(
			data => {
				this.unreadCnt = data["unreadCount"];
				this.unlinkedCnt = data["unlinkCount"];
			},
			(error) => {

			}
		);
	}

	getFollowUpCount() {
		this.apiService.getFollowupCount().subscribe(
			(data:any) => {
				this.followupcnt = data.count;
			},
			(error) => {

			}
		);
	}

	allEventCount() {
		this.apiService.allEventCount().subscribe(
			data => {
				this.alleventcnt = data["allCase"];
				this.openEventcnt = data["openCase"];
			},
			(error) => {

			}
		);
	}

	fixDate(dateString) {
		var date = new Date(dateString.replace(/-/g, "/"));
		const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		var day = date.getDate();
		var month = monthNames[date.getMonth()];
		var year = date.getFullYear();
		var hour = date.getHours();
		var minutes = date.getMinutes() + "";
		var seconds = date.getSeconds();
		var ampm = hour >= 12 ? 'pm' : 'am';
		hour = hour % 12;
		hour = hour ? hour : 12;
		minutes = parseInt(minutes) < 10 ? '0' + minutes : minutes;
		var strTime = hour + ':' + minutes + ' ' + ampm;

		day = this.checkZero(day);
		month = this.checkZero(month);
		hour = this.checkZero(hour);
		minutes = this.checkZero(minutes);
		seconds = this.checkZero(seconds);

		let dateStr = day + ", " + month + " " + year + " " + strTime;
		return dateStr;
	}

	set_color(eve) {
		if (eve.flag === 4) { return { backgroundColor: '#c71b09', 'border-color': '#c71b09', 'font-weight': 'bold' }; }
		else if (eve.flag === 2) { return { backgroundColor: '#e76e1e', 'border-color': '#e76e1e', 'font-weight': 'bold' }; }
		else if (eve.flag === 5) { return { backgroundColor: '#3f51b5', 'border-color': '#3f51b5', 'font-weight': 'bold' }; }
		else if (eve.flag === 3) { return { backgroundColor: '#1b8d08', 'border-color': '#1b8d08', 'font-weight': 'bold' }; }
		else if (eve.flag === 1) { return { backgroundColor: '#363f45', 'border-color': '#363f45', 'font-weight': 'bold' }; }
		else { return { backgroundColor: '#363f45', 'border-color': '#363f45', 'font-weight': 'bold' }; }
	}

	flagOpenEventsOld(flag) {
		this.excelDownloadParams = { category: 'Open', flag: flag, column: '', sortType: '', search: '', isReplied: this.tableparams.isReplied, tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure, doctor:this.tableparams.doctor };
		this.tableparams.flag = flag;
		this.tableparams.column = "";
		this.tableparams.sortType = "";
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList();
	}

	flagOpenEvents() {
		this.excelDownloadParams = { category: 'Open', flag: this.tableparams.flag, column: '', sortType: '', search: '', isReplied: this.tableparams.isReplied, tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure, doctor:this.tableparams.doctor };
		this.tableparams.column = "";
		this.tableparams.sortType = "";
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList();
	}

	toggleNotReplied() {

		/* this.tableparams.isReplied = this.tableparams.isReplied == 0 ? 1 : 0; */
		this.excelDownloadParams = { category: 'Open', flag: this.tableparams.flag, column: '', sortType: '', search: '', isReplied: this.tableparams.isReplied, tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure, doctor:this.tableparams.doctor };
		this.tableparams.column = "";
		this.tableparams.sortType = "";
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList();
	}

	sortTableBy(sorby) {
		var sortType = "";
		this.tableparams.sortType == "asc" ? sortType = "desc" : sortType = "asc";

		this.excelDownloadParams = { category: 'Open', flag: '', column: sorby, sortType: sortType, search: '', isReplied: this.tableparams.isReplied, tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure, doctor:this.tableparams.doctor };
		this.tableparams.column = sorby;
		/*this.tableparams.sortType == "asc" ? this.tableparams.sortType="desc" : this.tableparams.sortType="asc";*/
		this.tableparams.sortType = sortType;
		this.tableparams.flag = null;
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList();
	}

	searchByTags(){
		this.excelDownloadParams = { category: 'Open', flag: this.tableparams.flag, column: '', sortType: '', search: '', isReplied: this.tableparams.isReplied, tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure, doctor:this.tableparams.doctor };
		this.isListFinished = false;
		this.tableparams.page = 0;
		this.eventlist = [];
		this.getEventList();
	}

	markForClosureChange(){
		/* this.tableparams.mark_for_closure = !this.tableparams.mark_for_closure; */
		this.excelDownloadParams = { category: 'Open', flag: this.tableparams.flag, column: '', sortType: '', search: '', isReplied: this.tableparams.isReplied, tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure, doctor:this.tableparams.doctor };
		this.tableparams.column = "";
		this.tableparams.sortType = "";
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList();
	}

	searchByDoctor(){
		this.excelDownloadParams = { category: 'Open', flag: this.tableparams.flag, column: '', sortType: '', search: '', isReplied: this.tableparams.isReplied, tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure, doctor:this.tableparams.doctor };
		this.isListFinished = false;
		this.tableparams.page = 0;
		this.eventlist = [];
		this.getEventList();
	}

	getEventList() {
		if (!this.isListFinished && !this.isLoading) {
			let params:any = {
				category: this.tableparams.category,
				limit: this.tableparams.limit,
				page: this.tableparams.page,
				search: this.tableparams.search,
				isReplied: this.tableparams.isReplied?1:0
			}
			for (let i = 0; i < this.tableparams.tags.length; i++) {
				let key = "tags["+i+"]";
				params[key] = this.tableparams.tags[i];
			}
			if (this.tableparams.column != "") {
				params["column"] = this.tableparams.column;
				params["sortType"] = this.tableparams.sortType;
			}
			if (this.tableparams.flag != null) {
				params["flag"] = this.tableparams.flag;
			}
			if (this.tableparams.doctor != null) {
				params["doctor"] = this.tableparams.doctor;
			}
			if (this.tableparams.mark_for_closure) {
				params["mark_for_closure"] = this.tableparams.mark_for_closure;
			}
			this.isLoading = true;
			console.log(params)
			this.apiService.eventList(params).subscribe(
				data => {
					this.isLoading = false;
					let events: any = data["list"];
					if (events.length == this.tableparams.limit) {
						this.tableparams.page += 1;
					} else {
						this.tableparams.page += 1;
						this.isListFinished = true;
					}
					this.eventlist = this.eventlist.concat(events);
					for (var i = 0; i < this.eventlist.length; i++) {
						//code for date filter
						//this.eventlist[i].createdAt = new Date(this.eventlist[i].createdAt);
						//code to bring case Id on top having new mail notification
						if (this.eventlist[i].id === this.notificationId) {
							this.eventlist[i].notification = 1;
							this.eventlist[i].emailStatus = 'Unread';
							var arrayelement = this.eventlist[i];
							this.eventlist.splice(i, 1);
							this.eventlist.unshift(arrayelement);
							this.eventlist = [...this.eventlist];
						} else {
							this.eventlist[i].notification = 0;
						}
						if (this.eventlist[i].symptoms !== null && typeof (this.eventlist[i].symptoms) === 'string') {
							try {
								this.eventlist[i].symptomsText = JSON.parse(this.eventlist[i].symptoms);
								this.eventlist[i].symptomsText = this.eventlist[i].symptomsText.join();
							} catch (error) {
								this.eventlist[i].symptomsText = "";
							}
						}else{
							this.eventlist[i].symptomsText = "";
						}
					}
				},
				(error) => {
					this.isLoading = false;
					this.isListFinished = true;
				}
			);
		}
	}

	getExcel() {
		this.excelDownloadLoader = true;
		this.apiService.exportCases(this.excelDownloadParams).subscribe(
			res => {
				let excelData: any = res["body"];
				this.excelDownloadLoader = false;
				let blob = new Blob([excelData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
				var today = new Date();
				var day = today.getDate() + "";
				var month = (today.getMonth() + 1) + "";
				var year = today.getFullYear() + "";
				var hour = today.getHours() + "";
				var minutes = today.getMinutes() + "";
				var seconds = today.getSeconds() + "";

				day = this.checkZero(day);
				month = this.checkZero(month);
				hour = this.checkZero(hour);
				minutes = this.checkZero(minutes);
				seconds = this.checkZero(seconds);

				let fileName = day + "-" + month + "-" + year;
				FileSaver.saveAs(blob, "OpenEventList_" + fileName + ".xlsx");
			},
			(error) => {
				this.excelDownloadLoader = false;
				this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
			}
		);
	}

	checkZero(data) {
		if (data != undefined && data.toString().length == 1) {
			data = "0" + data;
		}
		return data;
	}

	openModal() {
		$("#dataModal").modal("show");
	}

	closeModal() {
		$("#dataModal").modal("hide");
	}

	clearDataModal() {
		this.analyticsPayload.fromDate = this.getOneDayBeforeDate();
		this.analyticsPayload.fromTime = this.getOneDayBeforeDate();
		this.analyticsPayload.toDate = new Date();
		this.analyticsPayload.toTime = new Date();
		this.analyticsPayload.case_status = null;
		this.analyticsPayload.doctor_id = null;
		this.analyticsError = "";
	}

	getEventData() {
		let payload:any = {};
		/* if(this.analyticsPayload.fromDate){
			if(!this.analyticsPayload.fromTime){
				this.analyticsError = "Select From Time.";
				return;
			}
			if(!this.analyticsPayload.toDate || !this.analyticsPayload.toTime){
				this.analyticsError = "Select To Date & Time.";
				return;
			}
			payload.from = this.getDateTimeStringFromDateTimePicker("from");
			payload.to = this.getDateTimeStringFromDateTimePicker("to");
			if(this.analyticsPayload.case_status){
				payload.case_status = this.analyticsPayload.case_status;
			}
		}else{
			if(this.analyticsPayload.case_status){
				payload.case_status = this.analyticsPayload.case_status;
			}else{
				this.analyticsError = "Please select either Dates or Case Status.";
				return;
			}
		} */
		if(this.analyticsPayload.fromDate){
			if(!this.analyticsPayload.fromTime){
				this.analyticsError = "Select From Time.";
				return;
			}
			payload.from = this.getDateTimeStringFromDateTimePicker("from");
		}
		if(this.analyticsPayload.toDate){
			if(!this.analyticsPayload.toTime){
				this.analyticsError = "Select From Time.";
				return;
			}
			payload.to = this.getDateTimeStringFromDateTimePicker("to");
		}
		if(this.analyticsPayload.case_status && this.analyticsPayload.case_status.length){
			payload.case_status = this.analyticsPayload.case_status;
		}
		if(this.analyticsPayload.doctor_id && this.analyticsPayload.doctor_id.length){
			payload.doctor_id = this.analyticsPayload.doctor_id;
		}
		this.analyticsError = "";
		this.showLoader = true;
		this.apiService.eventsData(payload).subscribe(data => {
			this.showLoader = false;
			this.closeModal();
			this.clearDataModal();
			Swal.fire('Success', data.toString(), 'success');
		}, (error) => {
			this.showLoader = false;
			let errorMsg:any = error.error;
			if(typeof errorMsg=='object'){
				this.analyticsError = "";
				for (const [key, value] of Object.entries(errorMsg)) {
					let val:any = value;
					this.analyticsError += val[0];
				}
				return;
			}else{
				this.analyticsError = "Something went wrong.";
				return;
			}
		});
	}

	getDateTimeStringFromDateTimePicker(type) {
		if (type == "from") {
			if(this.analyticsPayload.fromDate){
				var date = new Date(this.analyticsPayload.fromDate);
				var time = new Date(this.analyticsPayload.fromTime);
			}else{
				return null;
			}
		} else {
			if(this.analyticsPayload.toDate){
				var date = new Date(this.analyticsPayload.toDate);
				var time = new Date(this.analyticsPayload.toTime);
			}else{
				return null;
			}
		}
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var year = date.getFullYear();
		var hours = time.getHours();
		var minutes = time.getMinutes();
		var seconds = time.getSeconds();
		var ampm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12;
		hours = hours ? hours : 12;
		var dayStr = day < 10 ? '0' + day : day;
		var monthStr = month < 10 ? '0' + month : month;
		var hoursStr = hours < 10 ? '0' + hours : hours;
		var secondsStr = seconds < 10 ? '0' + seconds : seconds;
		var minutesStr = minutes < 10 ? '0' + minutes : minutes;
		var fromStr = year + "-" + monthStr + "-" + dayStr + " " + hoursStr + ':' + minutesStr + ":" + secondsStr + ' ' + ampm;
		return fromStr;
	}

	doctorList: any = [];
	getDoctorList(search) {
		var params = { page: 0, limit: 100 };
		if (search != "") {
			params["search"] = search;
		}
		this.apiService.userList(params).subscribe(
			data => {
				let docList: any = data;
				docList = docList.filter(obj => obj.userType == 'Doctor');
				this.doctorList = docList;
			},
			(error) => {

			}
		);
	}

	doctorCustomSearch(term: string, item: any) {
		term = term.toLocaleLowerCase();
		let isNameMatched = false;
		if (item.doct_name != null) {
			isNameMatched = item.doct_name.toLocaleLowerCase().indexOf(term) > -1;
		}
		let isTypeMatched = false;
		/*if(item.userType!=null){
				isTypeMatched = item.userType.toLocaleLowerCase().indexOf(term) > -1;
			}*/
		return isNameMatched || isTypeMatched;
	}

	searchFunctionDoctor(event) {
		this.getDoctorList(event["term"]);
	}

	ngOnDestroy() {
		this.formCtrlSub.unsubscribe();
	}
}
