import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject, BehaviorSubject, forkJoin } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { TreeviewItem } from 'ngx-treeview';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	baseURL = environment.API_GENERAL_URL + "api/";

	private mailCountChange = new Subject<any>();
	private mailOnlyCountChange = new Subject<any>();
	private notifiViewChange = new Subject<any>();
	private sessionExpMsg = new Subject<any>();

	constructor(private httpClient: HttpClient, private router: Router) { }

	sendCountChangeEvent() {
		this.mailCountChange.next();
	}
	getCountChangeEvent(): Observable<any> {
		return this.mailCountChange.asObservable();
	}

	sendOnlyCountChangeEvent() {
		this.mailOnlyCountChange.next();
	}
	getOnlyCountChangeEvent(): Observable<any> {
		return this.mailOnlyCountChange.asObservable();
	}

	sendNotiViewEvent() {
		this.notifiViewChange.next();
	}
	getNotiViewEvent(): Observable<any> {
		return this.notifiViewChange.asObservable();
	}

	sendSessionExpMessage() {
		this.sessionExpMsg.next();
	}
	getSessionExpMessage(): Observable<any> {
		return this.sessionExpMsg.asObservable();
	}

	public loginAPI(user) {
		var data = { email: user.email, password: user.password }
		return this.httpClient.post(this.baseURL + "login", data);
	}

	public passwordReset(email) {
		var data = { email: email }
		return this.httpClient.post(this.baseURL + "passwordReset", data);
	}

	public newPassword(data) {
		return this.httpClient.post(this.baseURL + "newPassword", data);
	}

	public getMailBoxCount() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "mailBoxList", { headers: headers });
	}

	public listMailFolders(page, limit, search) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const params: any = { page: page, limit: limit, search: search };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "listMailFolders", options);
	}

	public fetchfolderCount(folderName) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const params: any = { folderName: folderName };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "fetchfolderCount", options);
	}

	public fetchLocalEmails(category, page, limit, search) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const params: any = { page: page, limit: limit, category: category, search: search };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "fetchLocalEmails", options);
	}

	public flagList() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "flagList", { headers: headers });
	}

	public assignFlag(mId, flag) {
		var data = { mailId: mId, flag: flag }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "assignFlag", data, { headers: headers });
	}

	public assignFlagToEvent(eventId, flag) {
		var data = { eventId: eventId, flag: flag }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "assignEventFlag", data, { headers: headers });
	}

	public moveMailListtoFolder(mIdList, id) {
		var data = { mailIdList: mIdList, mailId: null, id: id }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "moveMailtoFolder", data, { headers: headers });
	}

	public moveMailListtoInbox(mIdList) {
		var data = { mailIdList: mIdList, mailId: null }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "moveMailtoInbox", data, { headers: headers });
	}

	public markListSpam(mId) {
		var data = { mailIdList: mId, mailId: null }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "assignAsSpam", data, { headers: headers });
	}

	public mailStatusSet(mId) {
		var data = { mailId: mId }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "mailStatusSet", data, { headers: headers });
	}

	public fetchReplyEmails(emailtext) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const params: any = { emailtext: emailtext };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "fetchReplyEmails", options);
	}

	public saveAsDraft(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "saveAsDraft", data, { headers: headers });
	}

	public composeMail(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "composeMail", data, { headers: headers });
	}

	public updateDraft(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "updateDraft", data, { headers: headers });
	}

	public sendDraft(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "sendDraft", data, { headers: headers });
	}

	public deleteDraft(id) {
		var data = { id: id };
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "deleteDraft", data, { headers: headers });
	}

	public mailReplyMessage(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "mailReplyMessage", data, { headers: headers });
	}

	public findMailIdDetails(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const params: any = { id: id };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "findMailIdDetails", options);
	}

	public fetchVessels() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "fetchVessels", { headers: headers });
	}

	public allEventCount() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "allEventCount", { headers: headers });
	}

	public eventList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "eventList", options);
	}

	public companyList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "companyList", options);
	}


	public companyNameValidation(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "companyNameValidation", options);
	}

	public companyEmailExist(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "companyEmailExist", options);
	}

	public countryCodes() {
		var headers = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get("./assets/files/countryCodes.json", { headers: headers });
	}

	public medicineMasterList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "medicineMasterList", options);
	}

	public companyDetails(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "companyDetails", data, { headers: headers });
	}

	public getCompanyDetails(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "companyDetails", options);
	}

	public updateCompany(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "updateCompany", data, { headers: headers });
	}

	public removeCompany(companyId) {
		var data = {
			companyId: companyId
		}
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "removeCompany", data, { headers: headers });
	}

	public amoscodeList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "amoscodeList", options);
	}


	public vesselList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "vesselList", options);
	}

	public removeVessel(vesselId) {
		var data = {
			vesselId: vesselId
		}
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "removeVessel", data, { headers: headers });
	}

	public vesselNote(note, vesselId) {
		var data = { "note": note, "vesselId": vesselId }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "vesselNote", data, { headers: headers });
	}

	public resetPassword(password, vesselId, vesselUserId) {
		var data = { "password": password, "vesselId": vesselId, vesselUserId: vesselUserId }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "resetPassword", data, { headers: headers });
	}

	public vesselEmailExist(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "vesselEmailExist", options);
	}

	public imoNoValidation(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "imoNoValidation", options);
	}

	public pointOfContact(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "pointOfContact", options);
	}

	public flagstateList() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "flagstateList", { headers: headers });
	}

	public vesselDetails(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "vesselDetails", data, { headers: headers });
	}

	public updateVessel(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "updateVessel", data, { headers: headers });
	}

	public getVesselDetails(vesselId) {
		var params = { vesselId: vesselId }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "vesselDetails", options);
	}

	public assignInventoryList(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "assignInventoryList", data, { headers: headers });
	}

	public listVesselCrews(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "listVesselCrews", options);
	}

	public removeSeafarerDetails(seafarerId) {
		var data = { seafarerId: seafarerId }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "removeSeafarerDetails", data, { headers: headers });
	}

	public rankList() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "rankList", { headers: headers });
	}

	public nationalityList(search) {
		var params = { search: search };
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "nationalityList", options);
	}

	public identityDocumentList() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "identityDocumentList", { headers: headers });
	}

	public addSeafarerDetails(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "addSeafarerDetails", data, { headers: headers });
	}

	public seafarerBasicDetails(seafarerId) {
		var params = { seafarerId: seafarerId };
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "seafarerBasicDetails", options);
	}

	public editSeafarerDetails(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "editSeafarerDetails", data, { headers: headers });
	}

	public fetchVesselFromImono(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "fetchVesselFromImono", options);
	}

	public portList(search) {
		var params = { search: search };
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "portList", options);
	}

	public fetchCandidateFromCcid(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "fetchCandidateFromCcid", options);
	}

	public symptomQuestions(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "symptomQuestions", data, { headers: headers });
	}

	public deviceList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "deviceList", options);
	}

	public createCustomEvent(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "createCustomEvent", data, { headers: headers });
	}

	public eventDetails(eventId) {
		var params = { eventId: eventId };
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "eventDetails", options);
	}

	public userList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "listDoctors", options);
	}

	public caseDoctorAssign(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "caseDoctorAssign", data, { headers: headers });
	}

	public changeEventstatus(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "changeEventstatus", data, { headers: headers });
	}

	public editEventDetails(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "editEventDetails", data, { headers: headers });
	}

	public saveEventnotes(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "saveEventnotes", data, { headers: headers });
	}

	public saveDailyNotes(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "saveDailyNotes", data, { headers: headers });
	}

	public IcdcodeList() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "IcdcodeList", { headers: headers });
	}

	public fetch_coordinates(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "fetch_coordinates", options);
	}

	public humanAnatomyAll() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "fetch_coordinates_all", { headers: headers });
	}

	public update_coordinates(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "update_coordinates", params, { headers: headers });
	}

	public eventMailList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "eventMailList", options);
	}

	public findTransactionId(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "findTransactionId", options);
	}

	public sendEventDraft(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "sendEventDraft", data, { headers: headers });
	}

	public mailUnlink(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "mailUnlink", data, { headers: headers });
	}

	public eventFileList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "eventFileList", options);
	}

	public caseReportList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "caseReportList", options);
	}

	public caseFilesUpload(file) {
		const formData = new FormData()
		formData.append('file', file)
		let _headers = new HttpHeaders();
		_headers = _headers.append("Authorization", localStorage.getItem('tokenKey'));
		return this.httpClient.post(this.baseURL + "caseFilesUpload", formData, { headers: _headers })
	}

	public caseFilesSave(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "caseFilesSave", data, { headers: headers });
	}

	public caseFilesDelete(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "caseFilesDelete", data, { headers: headers });
	}

	public saveCaseData(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "saveCaseData", data, { headers: headers });
	}

	public caseAnalysisList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "caseAnalysisList", options);
	}

	public exportCases(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "exportCases", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public eventInVessel(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "eventInVessel", options);
	}

	public getCandidateAndVesselDetails(crewId) {
		var params = { crewId: crewId }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "getCandidateAndVesseldetl", options);
	}

	public eventFromMailRequest(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "eventFromMailRequest", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public userProfileList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "usertypeList", options);
	}

	public isUserEmailExist(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "userEmailExist", options);
	}

	public addUser(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "addDoctor", data, { headers: headers });
	}

	public getUserDetails(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "doctorDetails", options);
	}

	public updateUser(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "editDoctorDetails", data, { headers: headers });
	}

	public removeDoctor(doc) {
		var data = {
			doctorId: doc
		}
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "removeDoctor", data, { headers: headers });
	}

	public resetUserPassword(password, userId) {
		var data = { "password": password, "userId": userId }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "newUserPassword", data, { headers: headers });
	}

	public accessRightList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "profileList", options);
	}

	public removeAccess(pro) {
		var data = {
			profileId: pro
		}
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "removeProfile", data, { headers: headers });
	}

	public isProfileExist(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "validateProfileName", options);
	}

	public addAccess(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "createProfile", data, { headers: headers });
	}

	public updateProfile(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "editProfile", data, { headers: headers });
	}

	public getProfileDetails(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "profileDetails", options);
	}

	public pointofcontactList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "pointOfContact", options);
	}

	public addPOC(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "pointOfContact", data, { headers: headers });
	}

	public getPOCDetails(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: id } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "pointOfContact/" + id, options);
	}

	public updatePOC(data, id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.put(this.baseURL + "pointOfContact/" + id, data, { headers: headers });
	}

	public removePOC(id) {

		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "pointOfContact/" + id, { headers: headers });
	}

	public getVesselInfo(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "vesselDetails", options);
	}

	public getVesselCheckList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "certificateAnswersList", options);
	}

	public updateSummery(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "saveSummaryData", data, { headers: headers });
	}

	public inventoryFileUpload(file, id) {
		const formData = new FormData()
		formData.append('file', file)
		formData.append('id', id)
		let _headers = new HttpHeaders();
		_headers = _headers.append("Authorization", localStorage.getItem('tokenKey'));
		return this.httpClient.post(this.baseURL + "inventoryFileUpload", formData, { headers: _headers })
	}

	getPolicies() {
		//return this.firestore.collection('users').snapshotChanges();
	}

	public chatFileUpload(file) {
		const formData = new FormData();
		formData.append('file', file);
		let _headers = new HttpHeaders();
		_headers = _headers.append("Authorization", localStorage.getItem('tokenKey'));
		return this.httpClient.post(this.baseURL + "fileUpload", formData, { headers: _headers });
	}

	public chatFileUploadOpen(file, channel) {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('channel', channel);
		return this.httpClient.post(this.baseURL + "chatFileUpload", formData);
	}


	public deleteFolderDetails(folderId) {
		var data = { folderId: folderId }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.delete(this.baseURL + "deleteFolderDetails", options);
	}

	public checkFolderExist(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "checkFolderExist", data, { headers: headers });
	}

	public addFolderDetails(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "addFolderDetails", data, { headers: headers });
	}

	public editFolderDetails(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "editFolderDetails", data, { headers: headers });
	}

	public fetchfolderData(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "fetchfolderData", options);
	}

	public caseFollowUpList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "caseFollowUpList", options);
	}

	public doctorDetails(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "doctorDetails", options);
	}

	public checkUnlink(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "checkUnlink", options);
	}

	public getEventData(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "getEventData", options);
	}

	private newUnlinkedMailCount = new Subject<any>();

	sendUnlinkCountIncrementEvent() {
		this.newUnlinkedMailCount.next();
	}
	getUnlinkCountIncrementEvent(): Observable<any> {
		return this.newUnlinkedMailCount.asObservable();
	}

	private newUnreadMailCount = new Subject<any>();

	sendUnreadCountIncrementEvent() {
		this.newUnreadMailCount.next();
	}
	getUnreadCountIncrementEvent(): Observable<any> {
		return this.newUnreadMailCount.asObservable();
	}

	public addCaseMeds(event, medicines) {
		var data = { eventId: event, meds: medicines }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "saveCaseMedicines", data, { headers: headers });
	}

	public addCaseVitals(event, vitals) {
		var data = { eventId: event, caseVitals: vitals }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "saveCaseVitals", data, { headers: headers });
	}

	public deleteMasterMed(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "medicineDetails/" + id, { headers: headers });
	}

	public medicineNameValidation(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "validateActiveIngredient", options);
	}

	public getMedicineDetails(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "medicineDetails/" + id, { headers: headers });
	}

	public createUpdateMedicines(data, id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		if (id == null) {
			return this.httpClient.post(this.baseURL + "medicineDetails", data, { headers: headers });
		} else {
			return this.httpClient.put(this.baseURL + "medicineDetails/" + id, data, { headers: headers });
		}
	}

	public loadQuestionMasters() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "modules", { headers: headers });
	}

	public removeQuestionModule(id) {
		var data = {
			'id': id
		}
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "modules/" + id, { headers: headers });
	}

	public addQuestionModule(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "modules", data, { headers: headers });
	}

	public updateQuestionModule(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.put(this.baseURL + "modules/" + data.id, data, { headers: headers });
	}

	public loadQuestionGroups(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "groups/" + id, { headers: headers });
	}

	public loadQuestions(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "questions/" + id, { headers: headers });
	}

	public addNewGroup(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "groups", data, { headers: headers });
	}

	public deleteGroup(id) {
		var data = {
			'id': id
		}
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "groups/" + id, { headers: headers });
	}

	public AddModuleNewQuestion(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "questions", data, { headers: headers });
	}

	public UpdateModuleQuestion(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.put(this.baseURL + "questions/" + data.question_id, data, { headers: headers });
	}

	public removeQuestion(id) {
		var data = {
			'id': id
		}
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "questions/" + id, { headers: headers });
	}

	private unselectBranch = new Subject<any>();
	sendUnselectBranchEvent() {
		this.unselectBranch.next();
	}
	getUnselectBranchEvent(): Observable<any> {
		return this.unselectBranch.asObservable();
	}

	public addCaseQuestions(eventId, questions) {
		var data = { eventId: eventId, questionAnswers: questions }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "saveCaseQnAnswers", data, { headers: headers });
	}

	public getMailSubject(id, sub) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		let data: any = { mail_eventId: id, subject: sub };
		return this.httpClient.post(this.baseURL + "generateSubject", data, { headers: headers });
	}

	public getAssignedTasks() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "taskAssign", { headers: headers });
	}

	public getUserRoles() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "userroleList", { headers: headers });
	}

	public getUsersByRole(roleId) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		var data = { roleId: roleId }
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "displayRoleUsers", options);
	}

	public taskAssign(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "taskAssign", data, { headers: headers });
	}

	public editAssignDoctor(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "editAssignDoctor", data, { headers: headers });
	}

	public removeAssignedDoctor(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "removeAssignedDoctor", data, { headers: headers });
	}

	private followUpRefresh = new Subject<any>();
	sendFollowUpRefreshEvent() {
		this.followUpRefresh.next();
	}
	getFollowUpRefreshEvent(): Observable<any> {
		return this.followUpRefresh.asObservable();
	}

	public saveQnAnsData(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "saveCaseQnAnsDetails", data, { headers: headers });
	}

	public appointmentList(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "appointmentList", options);
	}

	public clipprofileList(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "clipprofileList", options);
	}

	public clipDoctorList(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "clipDoctorList", options);
	}

	public clipClinicList(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "clipClinicList", options);
	}

	public purposeList(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "purposeList", options);
	}

	public departmentList() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "departmentList", { headers: headers });
	}

	public subdepartmentList() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "subdepartmentList", { headers: headers });
	}

	public createAppointment(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "createAppointment", data, { headers: headers });
	}

	public imageUpload(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "imageUpload", data, { headers: headers });
	}

	public candidateImageFileUpload(file) {
		const formData = new FormData()
		formData.append('file', file)
		let _headers = new HttpHeaders();
		_headers = _headers.append("Authorization", localStorage.getItem('tokenKey'));
		return this.httpClient.post(this.baseURL + "candidateImageFileUpload", formData, { headers: _headers })
	}

	public appointmentDetails(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "appointmentDetails", options);
	}

	public updateAppointment(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "updateAppointment", data, { headers: headers });
	}

	public removeAppointment(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "removeAppointment", data, { headers: headers });
	}

	public candidateReportList(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "candidateReportList", options);
	}

	public candidateActivityDetails(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "candidateActivityDetails", options);
	}

	public reportUpload(file) {
		const formData = new FormData()
		formData.append('file', file)
		let _headers = new HttpHeaders();
		_headers = _headers.append("Authorization", localStorage.getItem('tokenKey'));
		return this.httpClient.post(this.baseURL + "reportUpload", formData, { headers: _headers })
	}

	public candidateReport(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "candidateReport", data, { headers: headers });
	}

	public rebookAppointment(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "rebookAppointment", data, { headers: headers });
	}

	public listSeafarer(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "seafarer", options);
	}

	public getVesselList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "fetchCompVessels", options);
	}

	public addSeafarer(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "seafarer", data, { headers: headers });
	}

	public getSeafarer(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: id } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "seafarer/" + id, options);
	}

	public updateSeafarer(data, id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.put(this.baseURL + "seafarer/" + id, data, { headers: headers });
	}

	public removeSeafarer(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "seafarer/" + id, { headers: headers });
	}

	public unlinkSeafarer(seafarerId, type) {
		var data = { seafarerId: seafarerId, type: type };
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "unlinkSeafarer", data, { headers: headers });
	}

	public getPOCEmails(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "getPOCEmails", options);
	}

	public vesselsMedicine(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "vesselInventoryDetails", options);
	}

	public AddMedicineBatch(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "AddMedicineBatch", data, { headers: headers });
	}

	public DisplayBatchList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "DisplayBatchList", options);
	}

	public updateMedicine(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "addPrescription", data, { headers: headers });
	}

	public assignInventoryInVessel(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "assignInventoryInVessel", data, { headers: headers });
	}

	public getVesselMedicines(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "medicinesVessel", options);
	}

	public updateMinQty(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "updateMedMinQty", data, { headers: headers });
	}

	public getMailDetails(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "fetchLocalSingleEmail", options);
	}

	public getExtensions() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "getAllExt", { headers: headers });
	}

	public extensionValidation(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "validateCallExt", options);
	}

	public getAgoraToken(channelName) {
		const formData = new FormData();
		formData.append('channelName', channelName);
		let _headers = new HttpHeaders();
		_headers = _headers.append("Authorization", localStorage.getItem('tokenKey'));
		return this.httpClient.post(this.baseURL + "agora/token", formData, { headers: _headers });
	}

	public getAgoraTokenOpen(channelName) {
		const formData = new FormData();
		formData.append('channelName', channelName);
		return this.httpClient.post(this.baseURL + "agora/openToken", formData);
	}

	public userLogOut() {
		var data = {};
		var headers = {
			'Authorization': localStorage.getItem('tokenKey')
		}
		return this.httpClient.post(this.baseURL + "logout", data, { headers: headers });
	}

	getPdf(details) {
		var data = details;

		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json'
		}

		return this.httpClient.post(this.baseURL + "generatePrescriptionReport", data, { headers: headers, observe: 'response', responseType: 'blob' as 'json' });

	}

	public casePrescriptionList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "casePrescriptionList", options);
	}

	public doneFollowUp(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "doneFollowUp/" + data.id, {}, { headers: headers });
	}

	public deleteVesselMedicines(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "removeMedicineInVessel", data, { headers: headers });
	}

	public userApptHP(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "userApptHP", params, { headers: headers });
	}

	public getSeafarerProfile(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "userHPDetails", options);
	}

	public markAllMailsReplied(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "makeAllMailReplied", params, { headers: headers });
	}

	public hpRegistrationList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "healthPassportRegList", options);
	}

	public getCaseSpecialist(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "caseSpecialistList", options);
	}

	public getGroups() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "getGroups", { headers: headers });
	}

	public addWellnessAppt(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "addWellnessAppt", params, { headers: headers });
	}

	public getWellnessAppt(seafarer_id) {
		var params = { seafarer_id: seafarer_id }
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "getWellnessAppt", options);
	}

	public eventsData(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		/* const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "eventsData", options); */
		return this.httpClient.post(this.baseURL + "eventsData", params, { headers: headers });
	}


	public postEmergencyNote(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}

		return this.httpClient.post(this.baseURL + "emergency", params, { headers: headers });
	}

	public postGlobalEmergencyNote(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}

		return this.httpClient.post(this.baseURL + "emergency-global", params, { headers: headers });
	}

	public getCompanyCommunicationEvent(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "companyCommunication/" + params, { headers: headers });
	}

	public postCompanyCommunicationEvent(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}

		return this.httpClient.post(this.baseURL + "addCompanyCommunication", params, { headers: headers });
	}

	public getPortVisitHistory(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "portVisit/" + params, { headers: headers });
	}

	public postPortVisitHistory(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}

		return this.httpClient.post(this.baseURL + "portVisit", params, { headers: headers });
	}

	public getCountries(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "countries", options);
	}

	public getCountryPorts(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "ports", options);

	}

	public postCompanyCaseStatus(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}

		return this.httpClient.post(this.baseURL + "companyCaseStatus", params, { headers: headers });
	}

	public getCompanyCaseStatus(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "companyCaseStatus/" + id, { headers: headers });
	}


	public getFlagStates(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "listFlagstates", options);
	}


	public getFlagStateMedicineList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "flagstateMedicineList", options);
	}

	public mainCompanyList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "company_master_list", options);
	}

	public addEditMainCompany(params, id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		if (id) {
			return this.httpClient.post(this.baseURL + "company_master/" + id, params, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "company_master", params, { headers: headers });
		}
	}

	public removeMainCompany(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "company_master/" + id, { headers: headers });
	}

	public getMainCompanyDetails(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "company_master/" + id, { headers: headers });
	}

	public company_user_list(params, compId) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "company_user_list/" + compId, options);
	}

	public removeMainCompanyUser(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "company_user/" + id, { headers: headers });
	}

	public addEditMainCompanyUser(params, id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		if (id) {
			return this.httpClient.post(this.baseURL + "company_user/" + id, params, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "company_user", params, { headers: headers });
		}
	}

	public getMainCompanyUserDetails(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "company_user/" + id, { headers: headers });
	}

	public fleetListByCompany(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "fleetList", options);
	}

	public updateCaseSymptoms(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "symptomUpdate", params, { headers: headers });
	}

	public updateFlagstateMedicine(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "updateFlagstateMedicine", data, { headers: headers });
	}

	public fleet_user_list(params, fleetId) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "fleet_user_list/" + fleetId, options);
	}

	public removeFleetUser(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "fleet_user/" + id, { headers: headers });
	}

	public addEditFleetUser(params, id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		if (id) {
			return this.httpClient.post(this.baseURL + "fleet_user/" + id, params, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "fleet_user", params, { headers: headers });
		}
	}

	public getFleetUserDetails(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "fleet_user/" + id, { headers: headers });
	}

	public searchMail(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "mail/searchMail", data, { headers: headers });
	}

	public eventReply(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "event-reply", data, { headers: headers });
	}

	public draftEventReply(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "draft-event-reply", data, { headers: headers });
	}

	public treatmentAdvisedMaster() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "treatment-advised-master", { headers: headers });
	}

	public getEventVitals(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "vitals/" + id, { headers: headers });
	}

	public get_default_text_editor_text() {
		return this.httpClient.get("./assets/files/default_text_editor_text.json");
	}

	public dropdown_options(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "dropdown-options/" + id, { headers: headers });
	}

	public add_analytics(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			/* 'Content-Type': 'application/json', */
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "add-analytics", data, { headers: headers });
	}

	public add_analytics1(data) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "add-analytics", data, { headers: headers });
	}

	public getCrewStatus(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "crew-status/" + id, { headers: headers });
	}

	public getCrewWorkStatus(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "crew-work-status/" + id, { headers: headers });
	}

	public getTreatmentAdvised(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "treatment-advised/" + id, { headers: headers });
	}

	public getFollowUp(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "follow-up/" + id, { headers: headers });
	}

	public getNextFollowUp(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "next-follow-up/" + id, { headers: headers });
	}

	public getCommMode(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "communication-mode/" + id, { headers: headers });
	}

	public getSpecialistConsulted(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "specialist-selected/" + id, { headers: headers });
	}

	public getPrecautionAdvised(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "precaution-advised/" + id, { headers: headers });
	}

	public getReviewerNotes(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "reviewers-notes/" + id, { headers: headers });
	}

	public getDoctorNotes(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "doctor-notes/" + id, { headers: headers });
	}

	public getSpecialistSelected(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "specialist-selected/" + id, { headers: headers });
	}

	public getCandidateSymptoms(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "candidate-symptoms/" + id, { headers: headers });
	}

	public getCandidateSymptomsHistory(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "candidate-symptoms-history/" + id, { headers: headers });
	}

	public addCandidateSymptoms(data: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "candidate-symptoms", data, { headers: headers });
	}

	public getAllSymptoms(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "all-symptoms", options);
	}

	public doneReviewerNote(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "confirm-reviewers-notes/" + id, { reviewers_notes_completed: true }, { headers: headers });
	}

	public saveReport(data: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "report", data, { headers: headers });
	}

	public getReport(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "report/" + id, { headers: headers });
	}

	public saveReport1(data: any, eventId: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "repatriation-report/" + eventId, data, { headers: headers });
	}

	public getReport1(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "repatriation-report/" + id, { headers: headers });
	}

	public getAllSpecialists() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "specialists", { headers: headers });
	}

	public getAllHandovers() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "handover", { headers: headers });
	}

	public createHandover(data: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "handover", data, { headers: headers });
	}

	public updateHandover(data: any, id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.put(this.baseURL + "handover/" + id, data, { headers: headers });
	}

	public deleteHandover(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "handover/" + id, { headers: headers });
	}

	public sendHandover() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "send-handover", {}, { headers: headers });
	}

	public deleteAnalytics(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "analytics/" + id, { headers: headers });
	}

	public mandatoryCheck(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "mandatory-check/" + id, { headers: headers });
	}

	public getRating(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "event-rating/" + id, { headers: headers });
	}

	public setRating(id: any, rating: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "event-rating/" + id, { rating: rating }, { headers: headers });
	}

	public getTagSuggestions() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "tags", { headers: headers });
	}

	public fetchMedicineTags() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "sub-symptom-tags", { headers: headers });
	}

	public fetchEventMedicineTags(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "event-sub-symptom-tags/" + id, { headers: headers });
	}

	public saveEventMedicineTags(subSymptoms: any, eventId: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.put(this.baseURL + "sub-symptoms/" + eventId, { sub_symptoms: subSymptoms }, { headers: headers });
	}

	public getMailTagSuggestions() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "mails/tags", { headers: headers });
	}

	public getEventTags(EventId: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "tags/" + EventId, { headers: headers });
	}

	public getMailTags(mailId: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "mails/tags/" + mailId, { headers: headers });
	}

	public saveEventTags(id: any, tags: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "tags/" + id, { tags: tags }, { headers: headers });
	}

	public saveMailTags(id: any, tags: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "mails/tags/" + id, { tags: tags }, { headers: headers });
	}

	public markForClosure(id: any, value: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "events/mark-for-closure/" + id + "/value/" + value, {}, { headers: headers });
	}

	public getMultipleMailDetails(mailIds: any): Observable<any[]> {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		let responses: any = [];
		for (let i = 0; i < mailIds.length; i++) {
			let params = { id: mailIds[i] };
			const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
			const options = { params: new HttpParams(httpParams), headers: headers };
			let response = this.httpClient.get(this.baseURL + "fetchLocalSingleEmail", options);
			responses.push(response);
		}
		return forkJoin(responses);
	}

	public getTemplateList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "template", options);
	}

	public deleteTemplate(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "template/" + id, { headers: headers });
	}

	public getTemplateDetails(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "template/" + id, { headers: headers });
	}

	public createUpdateTemplate(data: any, id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		if (id) {
			return this.httpClient.put(this.baseURL + "template/" + id, data, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "template", data, { headers: headers });
		}
	}

	public getRankList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "rank", options);
	}

	public deleteRank(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "rank/" + id, { headers: headers });
	}

	public deleteMultipleRanks(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "rank/" + id, { headers: headers });
	}

	public getRankDetails(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "rank/" + id, { headers: headers });
	}

	public createUpdateRank(data: any, id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		if (id) {
			return this.httpClient.put(this.baseURL + "rank/" + id, data, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "rank", data, { headers: headers });
		}
	}

	public getNationalityList(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "nationality", options);
	}

	public deleteNationality(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "nationality/" + id, { headers: headers });
	}

	public deleteMultipleNationality(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "nationality/" + id, { headers: headers });
	}

	public getNationalityDetails(id) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "nationality/" + id, { headers: headers });
	}

	public createUpdateNationality(data: any, id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		if (id) {
			return this.httpClient.put(this.baseURL + "nationality/" + id, data, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "nationality", data, { headers: headers });
		}
	}

	public getMedicineSuggestion(params) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "medicine-suggestion", options);
	}

	public createUpdateShoreAssistance(data: any, id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		if (id) {
			return this.httpClient.put(this.baseURL + "shore-assistance/" + id, data, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "shore-assistance", data, { headers: headers });
		}
	}

	public getShoreAssistance(params: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "shore-assistance", options);
	}

	public deleteShoreAssistance(id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.delete(this.baseURL + "shore-assistance/" + id, { headers: headers });
	}

	public getFollowupCount() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "followup-count", { headers: headers });
	}

	public getCountryList() {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "country_list", { headers: headers });
	}

	public getPortsByCountry(countryCode: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "port_list/" + countryCode, { headers: headers });
	}

	public getCLIPFiles(eventId: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get(this.baseURL + "clip-files/" + eventId, { headers: headers });
	}

	public signOffClip(data: any, id: any) {
		var headers = {
			'Authorization': localStorage.getItem('tokenKey'),
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.post(this.baseURL + "clip/sign-off/" + id, data, { headers: headers });
	}
}



export interface HttpParamsOptions {
	fromString?: string;
	fromObject?: {
		[param: string]: string | string[];
	};
	encoder?: HttpParameterCodec;
}

interface HttpParameterCodec {
	encodeKey(key: string): string
	encodeValue(value: string): string
	decodeKey(key: string): string
	decodeValue(value: string): string
}

