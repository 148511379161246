import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {RefreshComponent} from './refresh/refresh.component';
import {LoginComponent} from './login/login.component';
import {RecoverComponent} from './recover/recover.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {EventlistComponent} from './eventlist/eventlist.component';
import {AddEventComponent} from './add-event/add-event.component';
import {CreateEventComponent} from './create-event/create-event.component';
import {EventallComponent} from './eventall/eventall.component';
import {HeadnavbarComponent} from './headnavbar/headnavbar.component';
/* import {AddMailFolderComponent} from './add-mail-folder/add-mail-folder.component'; */
import {VideoComponent} from './video/video.component';
import {SetupComponent} from './question-master/setup/setup.component';
import {ChildTokenGuard} from './Guards/child-token.guard';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'refresh', component: RefreshComponent},
  {path: 'login', component: LoginComponent},
  {path: 'recover', component: RecoverComponent},
  {path: 'setnewpassword/:email/:token', component: ResetPasswordComponent},
  {
    path: 'app', component: HeadnavbarComponent, data: {breadcrumb: 'TopNavBar'}, canActivateChild:[ChildTokenGuard], children: [
      {
        path: 'eventList', component: EventlistComponent
      },
      {
        path: 'eventList/:search', component: EventlistComponent
      },
      {path: 'allEvents', component: EventallComponent},
      {path: 'allEvents/:vesselId', component: EventallComponent},
      {path: 'allEvents/:vesselId/:transactionId', component: EventallComponent},
      {path: 'generateEvent', component: AddEventComponent},
      {path: 'generateVesselEvent/:transactionId/:candidateId/:vesselId', component: AddEventComponent},
      {path: 'createEvent/:transactionId/:vesselId', component: CreateEventComponent},
      {
        path: 'eventDetails/:eventId',
        loadChildren: () => import('./event-details1/event-details1.module').then(m => m.EventDetails1Module)
      },
      {
        path: 'mailbox',
        data: {breadcrumb: 'Folder List'},
        loadChildren: () => import('./lazy-mailbox/lazy-mailbox.module').then(m => m.LazyMailboxModule)
      },
      {path: 'admin', loadChildren: () => import('./admin-lazy/admin-lazy.module').then(m => m.AdminLazyModule)},
      /* {path: 'addMailFolder', component: AddMailFolderComponent},
      {path: 'editMailFolder/:folderId', component: AddMailFolderComponent}, */
      {path: 'videoMeeting', component: VideoComponent},
      {path: 'videoMeeting', component: VideoComponent},
      {path: 'questionSetup', component: SetupComponent},
      {
        path: 'addQuestions/:id',
        loadChildren: () => import('./question-master-lazy/question-master-lazy.module').then(m => m.QuestionMasterLazyModule)
      },
      {
        path: 'assignDoctor',
        loadChildren: () => import('./assign-doctor-lazy/assign-doctor-lazy.module').then(m => m.AssignDoctorLazyModule)
      },
      {path: 'videoCall', loadChildren: () => import('./video-call-lazy/video-call-lazy.module').then(m => m.VideoCallLazyModule)},
    ]
  },
  {path: 'joinCall', loadChildren: () => import('./video-call-lazy/video-call-lazy.module').then(m => m.VideoCallLazyModule)},
  {path: '**', redirectTo: '/login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
