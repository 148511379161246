<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">
<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700" rel="stylesheet">
<section class="content">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index"
        [class.active]="i===breadcrumbList.length-1">
      <a [routerLink]="item.path" *ngIf="i!==breadcrumbList.length-1">
        <b>{{ item.name }}</b>
      </a>
      <span *ngIf="i===breadcrumbList.length-1"><b>{{ item.name }}</b></span>
    </li>
  </ol>
  <div *ngIf="moduleAccess=='Full' || moduleAccess=='Readonly'" class="form-group row">
    <div class="col-sm-12">
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="app-view-header headerstyle">Question Master</div>
          <hr/>
          <div class="form-horizontal">
            <div class="panel panel-info">
              <div class="row">
                <div *ngFor="let types of questionMasterList; let i = index" class="col-lg-3 col-sm-6">
                  <div class="panel panel-{{types.module_bg_color}}">
                    <div class="panel-heading bg-{{types.module_bg_color}}">
                      <div class="clearfix">
                        <div *ngIf="moduleAccess=='Full'" class="pull-left"><i title="Edit" id="editgpId{{i}}" (click)="editModule(i)"
                                                  style="cursor: pointer" class="fa fa-edit"></i>
                        </div>
                        <div *ngIf="moduleAccess=='Full'" class="pull-right">
                          <em title="Delete" id="deletegpId{{i}}" (click)="deleteModule(types.id,i)"
                              style="cursor: pointer" class="icon-delete"></em>
                        </div>
                      </div>
                      <div class="panel-title">
                        <div class="text-uppercase text-center pv-lg bg-{{types.module_bg_color}}">{{types.module_name}}
                          <br/>
                          <small class="text-muted text-capitalize">{{types.description}}</small>
                        </div>
                        <div class="text-center">
                          <a id="changegpId{{i}}" [routerLink]="['/app/addQuestions',types.id]">
                            <small *ngIf="moduleAccess=='Full'"
                              style="text-underline-position: under;text-decoration-line: underline;font-size: 16px;"
                              class="text-white">Add Questions</small>
                            <small *ngIf="moduleAccess=='Readonly'"
                              style="text-underline-position: under;text-decoration-line: underline;font-size: 16px;"
                              class="text-white">View Questions</small>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="moduleAccess=='Full'" id="addnewgpId" (click)="addNewQuestionCategory()" class="col-lg-3 col-sm-6"
                     style="cursor: pointer;">
                  <div style="height: 180px" class="box-placeholder text-center">
                    <ul class="list-table fh">
                      <li class="fh v-middle"><a class="text-muted lead m0">Start New Module</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div *ngIf="qnCategoryActive==&quot;addnew&quot;" class="row">
                <div class="col-sm-12 mt20">
                  <div class="panel">
                    <div class="panel-body">
                      <div class="row">
                        <div class="col-lg-2 col-sm-2">
                          <label><span style="color: #b40a09;">*</span>Module Name:</label>
                          <input id="moduleNameid" type="text" placeholder="Module Name" [(ngModel)]="newModule.module_name" required=""
                                 class="form-control target"/>
                        </div>
                        <div class="col-lg-2 col-sm-2">
                          <label><span style="color: #b40a09;"></span>Color:</label>
                          <ng-select id="modulecolorid" [items]="colorList" [clearable]="false" placeholder="Select Color"
                                     [(ngModel)]="newModule.module_bg_color">
                            <ng-template ng-label-tmp let-item="item">
                              {{item}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                              {{item}}
                            </ng-template>
                          </ng-select>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <label><span style="color: #b40a09;"></span>Description:</label>
                          <textarea id="moduleDescId" [(ngModel)]="newModule.description" class="form-control"></textarea>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <button id="moduleAddId" type="submit" [disabled]="!newModule.module_name" (click)="addNewModule()"
                                  class="btn btn-success btn-xs mr">Save
                          </button>
                          <button id="moduleCloseId" type="button" (click)="closeNewModule()" class="btn btn-default btn-xs">Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="moduleAccess!='Full' && moduleAccess!='Readonly'">
    <div class="text-center">
      <h4>You don&apos;t have access to this section.</h4>
    </div>
  </div>
</section>
