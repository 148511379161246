<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">
<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700" rel="stylesheet">
<section class="content">
	<div *ngIf="moduleAccess=='Full' || moduleAccess=='Readonly'" class="form-group row">
		<!-- <div [routerLink]="['/app/mailbox/folder/unread']" id="linkUnread"
			class="text-center col-lg-3 col-sm-3 col-xs-6" style="cursor: pointer;">
			<div ripple="" style="border-radius: 4%;" class="panel panel-default">
				<div style="border-radius: 4%;" class="panel-body bg-NewMail">
					<h1 class="text-thin text-white" style="margin-bottom: 0px;"><i class="fa fa-envelope"></i>
					</h1>
					<h2 class="text-white" style="margin-bottom: 0px;">
						Unread Emails
					</h2>
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<strong>{{unreadCnt || 0}}</strong>
					</h1>
				</div>
				<span class="ripple"></span>
			</div>
		</div> -->
		<div [routerLink]="['/app/admin/followup']" id="linkFollowup"
			class="text-center col-lg-3 col-sm-3 col-xs-6" style="cursor: pointer;">
			<div ripple="" style="border-radius: 4%;" class="panel panel-default">
				<div style="border-radius: 4%;" class="panel-body bg-NewMail">
					<h1 class="text-thin text-white" style="margin-bottom: 0px;"><i class="fa fa-calendar-plus-o"></i>
					</h1>
					<h2 class="text-white" style="margin-bottom: 0px;">
						Scheduled Calls
					</h2>
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<strong>{{followupcnt || 0}}</strong>
					</h1>
				</div>
				<span class="ripple"></span>
			</div>
		</div>
		<div [routerLink]="['/app/mailbox/folder/unlinked']" id="linkUnlinked"
			class="text-center col-lg-3 col-sm-3 col-xs-6" style="cursor: pointer;">
			<div ripple="" style="border-radius: 4%;" class="panel panel-default">
				<div style="border-radius: 4%;" class="panel-body bg-unlink">
					<h1 class="text-thin text-white" style="margin-bottom: 0px;"><i class="fa fa-chain-broken"></i>
					</h1>
					<h2 class="text-white" style="margin-bottom: 0px;">
						Un-Linked
					</h2>
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<strong>{{unlinkedCnt || 0}}</strong>
					</h1>
				</div>
				<span class="ripple"></span>
			</div>
		</div>
		<div id="linkListVessel" class="text-center col-lg-3 col-sm-3 col-xs-6">
			<div ripple="" style="border-radius: 4%;" class="panel panel-default">
				<div style="border-radius: 4%;" class="panel-body bg-vessl">
					<h1 class="text-thin text-white" style="margin-bottom: 0px;"><i class="fa fa-folder"></i>
					</h1>
					<h2 class="text-white" style="margin-bottom: 0px;">
						Open Cases
					</h2>
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<strong>{{openEventcnt || 0}}</strong>
					</h1>
				</div>
				<span class="ripple"></span>
			</div>
		</div>
		<div title="All Case" class="text-center col-lg-3 col-sm-3 col-xs-6" style="cursor: pointer;"
			[routerLink]="['/app/allEvents']" id="linkAllEvents">
			<div ripple="" style="border-radius: 4%;" class="panel panel-default">
				<div style="border-radius: 4%;" class="panel-body bg-shps">
					<h1 class="text-thin text-white" style="margin-bottom: 0px;"><i class="fa fa-folder"></i>
					</h1>
					<h2 class="text-white" style="margin-bottom: 0px;">
						All Cases
					</h2>
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<strong>{{alleventcnt || 0}}</strong>
					</h1>
				</div>
				<span class="ripple"></span>
			</div>
		</div>
	</div>
	<div *ngIf="moduleAccess=='Full' || moduleAccess=='Readonly'" class="form-group row">
		<div class="col-sm-12">
			<div class="panel panel-default">
				<div style="background-color: #586acc !important;" class="panel-heading">
					<h2 class="text-thin text-white text-center mt0 mb0">
						<strong style="font-size: 23px;">Open Cases</strong>
					</h2>
				</div>
				<div>
					<div style="padding-top: 10px;width: 100%;" class="pull-left">
						<input autofocus id="searchBox" placeholder="Search By Case ID, Vessel, Candidate, Fleet"
							style="font-size: 16px;margin-left: 5px;width: 20%;float: left;" [formControl]="searchControl"
							 class="form-control" />
						<ng-select style="margin-left: 5px;width: 30%;float: left;" [items]="tagMaster" appendTo="body" [clearable]="true" [multiple]="true"
						placeholder="Search By Tags" id="tags" name="tags" [(ngModel)]="tableparams.tags" (change)="searchByTags()">
						</ng-select>
						<!-- <label style="margin-top: 5px;margin-left: 10px;cursor: pointer;">
							<input type="checkbox" id="checkMarkForClosure" [(ngModel)]="tableparams.mark_for_closure" [ngModelOptions]="{standalone: true}" (change)="markForClosureChange()"/>
							Marked for closure
						</label> -->
						<!-- <ng-select style="margin-left: 5px;width: 30%;float: left;" appendTo="body" id="doctorSearch" [items]="doctorList" bindLabel="doct_name" bindValue="id"
							[clearable]="true" placeholder="Search By Doctor"
							[searchFn]="doctorCustomSearch" (search)="searchFunctionDoctor($event)"
							(change)="searchByDoctor()" [(ngModel)]="tableparams.doctor"
							[ngModelOptions]="{standalone: true}">
							<ng-template ng-label-tmp let-item="item">
								{{item.doct_name}}
							</ng-template>
							<ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
								{{item.doct_name}}<br>type: {{item.userType}}
							</ng-template>
							</ng-select> -->
						<!-- <div ngbDropdown container="body" style="display: inline-block;" class="pull-right">
							<button type="button" class="btn btn-sm btn-primary mr-sm" id="filterDropDown" ngbDropdownToggle>
								<i class="fa fa-filter" aria-hidden="true"></i>
								Options
								<i class="fa fa-angle-down" aria-hidden="true"></i>
							</button>
							<div ngbDropdownMenu aria-labelledby="filterDropDown" style="width: fit-content;">
								<button ngbDropdownItem id="filterBtn0" (click)="toggleNotReplied()">
									<span [ngStyle]="{'cursor':tableparams.isReplied==1 ?'default':'pointer'}"
										[ngClass]="{'text-bold text-black text-uppercase':tableparams.isReplied==1}"
										style="padding-right: 20px;" id="toggleNotRepliedBtn"><i
											[ngStyle]="{'cursor':tableparams.isReplied==1 ?'default':'pointer'}" aria-hidden="true"
											style="color:#3f51b5" class="fa fa-envelope mr"></i>
										<span [ngStyle]="{'border-bottom':tableparams.isReplied==1 ?'2px solid #0a0a0a':''}">Not
											Replied</span>
									</span>
								</button>
								<button ngbDropdownItem id="filterBtn1" (click)="markForClosureChange()">
									<span [ngStyle]="{'cursor':tableparams.mark_for_closure ?'default':'pointer'}"
										[ngClass]="{'text-bold text-black text-uppercase':tableparams.mark_for_closure}"
										style="padding-right: 20px;" id="toggleNotRepliedBtn"><i
											[ngStyle]="{'cursor':tableparams.mark_for_closure ?'default':'pointer'}" aria-hidden="true"
											style="color:#3f51b5" class="fa fa-window-close mr"></i>
										<span [ngStyle]="{'border-bottom':tableparams.mark_for_closure ?'2px solid #0a0a0a':''}">Marked for closure</span>
									</span>
								</button>
								<button ngbDropdownItem id="filterBtn2" (click)="flagOpenEvents(5)">
									<span [ngStyle]="{'cursor':tableparams.flag==5 ?'default':'pointer'}"
										[ngClass]="{'text-bold text-primary text-uppercase':tableparams.flag==5}"
										style="padding-right: 20px;" id="flagOpenEventsBtn5"><i
											aria-hidden="true" class="fa fa-square text-primary mr"></i>
										<span
											[ngStyle]="{'border-bottom':tableparams.flag==5 ?'2px solid #3f51b5':''}">CODE BLUE</span>
									</span>
								</button>
								<button ngbDropdownItem id="filterBtn3" (click)="flagOpenEvents(1)">
									<span [ngStyle]="{'cursor':tableparams.flag==1 ?'default':'pointer'}"
										[ngClass]="{'text-bold text-black text-uppercase':tableparams.flag==1}"
										style="padding-right: 20px;" id="flagOpenEventsBtn1"><i
											[ngStyle]="{'cursor':tableparams.flag==1 ?'default':'pointer'}" aria-hidden="true"
											style="color:#0a0a0a" class="fa fa-flag mr"></i>
										<span [ngStyle]="{'border-bottom':tableparams.flag==1 ?'2px solid #0a0a0a':''}">Unassigned</span>
									</span>
								</button>
								<button ngbDropdownItem id="filterBtn4" (click)="flagOpenEvents(4)">
									<span [ngStyle]="{'cursor':tableparams.flag==4 ?'default':'pointer'}"
										[ngClass]="{'text-bold text-red text-uppercase':tableparams.flag==4}"
										style="padding-right: 20px;" id="flagOpenEventsBtn4"><i
											aria-hidden="true" class="fa fa-flag text-red mr"></i>
										<span
											[ngStyle]="{'border-bottom':tableparams.flag==4 ?'2px solid #c71b09':''}">Emergency</span>
									</span>
								</button>
								<button ngbDropdownItem id="filterBtn5" (click)="flagOpenEvents(2)">
									<span [ngStyle]="{'cursor':tableparams.flag==2 ?'default':'pointer'}"
										[ngClass]="{'text-bold text-orange text-uppercase':tableparams.flag==2}"
										style="padding-right: 20px;" id="flagOpenEventsBtn2"><i
											aria-hidden="true" class="fa fa-flag text-orange mr"></i>
										<span
											[ngStyle]="{'border-bottom':tableparams.flag==2 ?'2px solid #e76e1e':''}">Routine</span>
									</span>
								</button>
								<button ngbDropdownItem id="filterBtn6" (click)="flagOpenEvents(3)">
									<span [ngStyle]="{'cursor':tableparams.flag==3 ?'default':'pointer'}"
										[ngClass]="{'text-bold text-green text-uppercase':tableparams.flag==3}"
										style="padding-right: 20px;" id="flagOpenEventsBtn3"><i
											aria-hidden="true" class="fa fa-flag text-green mr"></i>
										<span [ngStyle]="{'border-bottom':tableparams.flag==3 ?'2px solid #1b8d08':''}">Improving</span>
									</span>
								</button>
								<button *ngIf="tableparams.flag" ngbDropdownItem id="filterBtn7" (click)="flagOpenEvents(null)">
									<span style="padding-right: 20px;cursor: pointer;"
										 id="flagOpenEventsBtnNull"><i aria-hidden="true"
											class="fa fa-refresh  mr text-info"></i> &nbsp;Show All</span>
								</button>
							</div>
						</div> -->
						<ng-select style="margin-left: 5px;width: 15%;float: left;" [items]="flagList" bindLabel="name" bindValue="id" appendTo="body" [clearable]="true" [multiple]="false"
						placeholder="Search By TRIAGE" [(ngModel)]="tableparams.flag" (change)="flagOpenEvents()">
						<ng-template ng-label-tmp let-item="item">
							<i [ngClass]="item.class"></i>{{item.name}}
						</ng-template>
						<ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
							<i [ngClass]="item.class"></i>{{item.name}}
						</ng-template>
						</ng-select>
						<div class="checkbox c-checkbox pull-left ml-sm" style="width:170px !important;">
							<label>
								<input type="checkbox" id="checkisReplied" [(ngModel)]="tableparams.mark_for_closure" [ngModelOptions]="{standalone: true}" (change)="markForClosureChange()" />
								<span class="fa fa-check"></span>
								Marked for closure
							</label>
						</div>
						<div class="checkbox c-checkbox pull-left" style="width:110px !important;margin-top: 10px;">
							<label>
								<input type="checkbox" id="checkisReplied" [(ngModel)]="tableparams.isReplied" [ngModelOptions]="{standalone: true}" (change)="toggleNotReplied()" />
								<span class="fa fa-check"></span>
								Not Replied
							</label>
						</div>
						<button *ngIf="userTypeName =='Basic Admin'" type="button" class="btn btn-sm btn-info ml mr pull-right"
							(click)="openModal()">Analytics</button>
						
						
						
						<!-- <i *ngIf="eventlist.length" style="cursor: pointer"
						(click)="getExcel()" title="Excel Download"
						class="fa fa-download fa-2x mr text-success pull-right"></i><i *ngIf="excelDownloadLoader"
						class="fa fa-spin fa-spinner mr fa-2x pull-right"></i> -->
					</div>
					<!-- <cdk-virtual-scroll-viewport itemSize="50" (scrolledIndexChange)="$event+7>=eventlist.length?getEventList():null"> -->
					<div class="table-responsive" style="max-height: 450px;overflow-y: auto;width: 100%;">
						<table class="table table-striped table-bordered table-hover">
							<thead>
								<tr>
									<th class="tableth_heading text-center">Case ID<i (click)="sortTableBy('id')"
											id="sortTableByBtnId" class="fa fa-sort ml" style="cursor: pointer;"></i>
									</th>
									<th class="tableth_heading text-center">Date Of Case<i
											(click)="sortTableBy('createdAt')" id="sortTableByBtncreatedAt"
											class="fa fa-sort ml" style="cursor: pointer;"></i>
									</th>
									<!-- <th class="tableth_heading text-center">Fleet Name<i (click)="sortTableBy('com_name')"
											id="sortTableByBtncom_name" class="fa fa-sort ml" style="cursor: pointer;"></i>
									</th> -->
									<th class="tableth_heading text-center">Vessel Name<i
											(click)="sortTableBy('vesselName')" id="sortTableByBtnvesselName"
											class="fa fa-sort ml" style="cursor: pointer;"></i>
									</th>
									<th class="tableth_heading text-center">Candidate Name<i
											(click)="sortTableBy('candname')" id="sortTableByBtncandname"
											class="fa fa-sort ml" style="cursor: pointer;"></i>
									</th>
									<th class="tableth_heading text-center">
										Symptoms
										<!-- <i (click)="sortTableBy('symptoms')" id="sortTableByBtnsymptoms" class="fa fa-sort ml" style="cursor: pointer;"></i> -->
									</th>
									<th class="tableth_heading text-center">Doctor Name<i
											(click)="sortTableBy('doctorName')" id="sortTableByBtndoctorName"
											class="fa fa-sort ml" style="cursor: pointer;"></i>
									</th>
									<th class="tableth_heading text-center">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let eve of eventlist; let i = index" class="table_td_style">
									<td class="text-center">{{eve.eventId}}
										<span *ngIf="eve.emailStatus=='Unread'" title="New mail" class="mr-sm"><i
												aria-hidden="true" class="fa fa-envelope"></i>
										</span>
										<span *ngIf="eve.isReplied==1" title="Not Replied"><i aria-hidden="true"
												style="color: #3f51b5;" class="fa fa-envelope"></i>
										</span>
									</td>
									<td class="text-center">{{fixDate(eve.createdAt)}}</td>
									<!-- <td class="text-center text-uppercase">{{eve.com_name}}</td> -->
									<td class="text-uppercase">
										<i class="fa fa-info-circle mr" [title]="'Fleet : ' + eve.com_name"></i>
										{{eve.vesselName}}
									</td>
									<td class="text-center text-uppercase">{{eve.candname}}</td>
									<td title="{{eve.symptomsText}}">{{eve.symptomsText | limitTo:'20'}}</td>
									<td class="text-center text-uppercase">{{eve.doctorName || '-'}}</td>
									<td class="text-center">
										<!-- <i class="fa fa-info-circle mr" [title]="'Symptoms : ' + eve.symptomsText"></i> -->
										<button title="View Case Details" [ngStyle]="set_color(eve)"
											[routerLink]="['/app/eventDetails',eve.id]"
											class="btn btn-xs text-white text-bold" id="btnViewDetails{{i}}">View</button>
									</td>
								</tr>
							</tbody>
						</table>
						<div *ngIf="!eventlist.length &amp;&amp; !isLoading" class="text-center text-bold">
							Data Not Found.
						</div>
						<div *ngIf="isLoading" class="text-center text-bold">
							<i aria-hidden="true" class="fa fa-spinner fa-spin"></i> Loading more events.
						</div>
						<app-infinite-scroll (scrolled)="getEventList()" *ngIf="!isLoading">
						</app-infinite-scroll>
						<div *ngIf="!isLoading && !isListFinished" class="text-center text-bold">
							<button id="btnLoadMore" title="Load More" (click)="getEventList()" class="btn btn-primary">Load More</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="moduleAccess!='Full' && moduleAccess!='Readonly'">
		<div class="text-center">
			<h4>You don&apos;t have access to this section.</h4>
		</div>
	</div>
</section>
<div class="modal fade" id="dataModal" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<!-- <form class="form-horizontal"> -->
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" (click)="clearDataModal();">&times;</button>
				<h4 class="modal-title"><b>Select Period</b></h4>
			</div>
			<div class="modal-body">
				<div class="row form-group">
					<div class="col-md-5">
						<label class="control-label">From Date:</label>
						<input matInput [(ngModel)]="analyticsPayload.fromDate" type="text" id="fromDate" class="form-control"
							[matDatepicker]="fromDtPicker" placeholder="" [readonly]="true"
							(click)="fromDtPicker.open()">
						<mat-datepicker #fromDtPicker></mat-datepicker>
					</div>
					<div class="col-md-5">
						<label class="control-label">From Time:</label>
						<input matTimepicker [(ngModel)]="analyticsPayload.fromTime" type="text" id="timepicker-example"
							class="form-control" #timePickerFrom="matTimepicker" [strict]="false" mode="12h"
							placeholder="" [disableDialogOpenOnClick]="false" readonly>
					</div>
					<div class="col-md-2">
						<button type="button" class="btn btn-xs btn-primary" style="margin-top: 30px;" (click)="analyticsPayload.fromDate=null;analyticsPayload.fromTime=null">Clear</button>
					</div>
				</div>
				<div class="row form-group">
					<div class="col-md-5">
						<label class="control-label">To Date:</label>
						<input matInput [(ngModel)]="analyticsPayload.toDate" type="text" id="toDate" class="form-control"
							[matDatepicker]="toDtPicker" placeholder="" [readonly]="true" (click)="toDtPicker.open()">
						<mat-datepicker #toDtPicker></mat-datepicker>
					</div>
					<div class="col-md-5">
						<label class="control-label">To Time:</label>
						<input matTimepicker [(ngModel)]="analyticsPayload.toTime" type="text" id="timepicker-example"
							class="form-control" #timePickerTo="matTimepicker" [strict]="false" mode="12h"
							placeholder="" [disableDialogOpenOnClick]="false" readonly>
					</div>
					<div class="col-md-2">
						<button type="button" class="btn btn-xs btn-primary" style="margin-top: 30px;" (click)="analyticsPayload.toDate=null;analyticsPayload.toTime=null">Clear</button>
					</div>
				</div>
				<div class="row form-group">
					<div class="col-md-12">
						<label class="control-label">Case Status:</label>
						<ng-select [items]="statusList" appendTo="body" bindLabel="name" bindValue="id" [clearable]="true" [multiple]="true" placeholder="Select" id="case_status" name="case_status" [(ngModel)]="analyticsPayload.case_status">
						</ng-select>
					</div>
				</div>
				<div class="row form-group">
					<div class="col-md-12">
						<label class="control-label">Assigned To:</label>
						<ng-select appendTo="body" id="doctorSearch" [items]="doctorList" bindLabel="doct_name" bindValue="id"
							[clearable]="true" [multiple]="true" placeholder="Select Doctor"
							[searchFn]="doctorCustomSearch" (search)="searchFunctionDoctor($event)"
							[(ngModel)]="analyticsPayload.doctor_id"
							[ngModelOptions]="{standalone: true}"></ng-select>
					</div>
				</div>
			</div>
			<div class="modal-footer text-center">
				<p *ngIf="analyticsError" style="color: maroon;">{{analyticsError}}</p>
				<button id="btnSubmit1" type="button" class="btn btn-success" (click)="getEventData()"><span
						style="margin-right: 5px;"><i *ngIf="showLoader" aria-hidden="true"
							class="fa fa-spinner fa-spin"></i></span>Submit</button>
				<button id="btnDelete" type="button" class="btn btn-primary" data-dismiss="modal"
					(click)="clearDataModal()">Close</button>
			</div>
			<!-- </form> -->
		</div>
	</div>
</div>